import { createSlice } from "@reduxjs/toolkit";
import { callApiService } from "../ApiService/";

const apiServicesSlice = createSlice({
    name: "apiService",
    initialState: {
        error: false,
        loading: 0,
        showSnackbar: false,
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state) => {
            state.loading = state.loading + 1;
        },
        removeLoading: (state, action) => {
            state.loading = state.loading - 1;
        },
        setShowSnackBar: (state, action) => {
            state.showSnackbar = action.payload;
        },
    },
});

export const callApi = async (dispatch, method, endpoint, payload = undefined) => {
	try {
		dispatch(setLoading());
		const response = await callApiService(method, endpoint, payload);
		console.log({apiResponse:response})
		const {data, error} = response
		if (data) {
			dispatch(removeLoading());
			if(data?.message){
				dispatch(
						setShowSnackBar({ message:data?.message, type: "success" })
					);
					setTimeout(() => {
						dispatch(setShowSnackBar(false));
					}, 4000);
			}
	
			return data?.data || null; 
		}
		else{
			dispatch(setError(error || "An error occurred"));
			dispatch(setShowSnackBar({ message: error || "Something went wrong!", type: "error" }));
			setTimeout(() => {
				dispatch(setShowSnackBar(false));
			}, 4000);
			dispatch(removeLoading());
			console.error("Error Occured: ", error);
		}
		
	} catch (error) {
		dispatch(removeLoading());
		dispatch(setError( "An error occurred"));
		dispatch(setShowSnackBar({ message: "Error !", type: "error" }));
		setTimeout(() => {
			dispatch(setShowSnackBar(false));
		}, 3000);
		setError(false)
		console.error("Error Occured: ", error);
	}
};

export const { setError, setLoading, removeLoading, setShowSnackBar } =
	apiServicesSlice.actions;

export default apiServicesSlice.reducer;
