import React from 'react'

const HeaderComponent = () => {
	const textStyles = {
		color: "#A8A8A8",
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "normal",
		fontFamily: "Readex Pro",
	};
	const cardStyles = {
		// borderRight: "1px solid #D4D4D4",
		display: "flex",
		height: "50px",
		padding: "0px 20px",
		alignItems: "center",
		gap: "10px",
		flexShrink: 0,
		marginBottom: "-10px",
	};
	return (
		<div style={cardStyles}>
			<div style={{ ...textStyles, flexBasis: "19%" }}>Brand</div>
			<div style={{ ...textStyles, flexBasis: "19%" }}>Instagram URL</div>
			<div style={{ ...textStyles, flexBasis: "19%" }}>Twitter URL</div>
			<div style={{ ...textStyles, flexBasis: "19%" }}>Tiktok URL</div>
			{/* <div style={{ ...textStyles, flexBasis: "19%" }}>SEC No</div> */}
			<div style={{ ...textStyles, flexBasis: "5%" }}>{""}</div>
		</div>
	);
};

export default HeaderComponent