import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Lacoste from "../../Assets/Lacoste.png";
import Tommy from "../../Assets/Tommy.png";
import BarChart from "../../CommonComponents/BarChart";
import GenAIText from "../../CommonComponents/GenAIText";
import LineChart from "../../CommonComponents/LineChart";
import { SelectInputStyles, DropdownOptionsStyles } from "../../CommonStyles";
import { socialMediaList } from "../../Constants";
import { getStatstics } from "../../Reducers/graphReducer";
import { shortNumberFormat } from "../../util";
import SentimentalAnalysisChart from "./SentimentalAnalysisChart";
import SocialMediaPost from "./SocialMediaPost";
import "./style.css";

const Graphs = () => {
	const dispatch = useDispatch();
	const [selectedSocialMedia, setSelectedSocialMedia] = useState(
		socialMediaList[0]
	);
	const [isLineChartVisible, setIsLineChartVisible] = useState(true);
	const [statisticsData, setStatisticsData] = useState({
		Instagram: {},
		Tiktok: {},
		Twitter: {},
	});

	useEffect(() => {
		if (isEmpty(statisticsData[selectedSocialMedia.label])) {
			getStatisticsData();
		}
	}, [selectedSocialMedia]);

	const topPosts = useMemo(() => {
		let list = [];
		statisticsData[selectedSocialMedia.label]?.top_posts?.forEach(
			(item) => {
				list = list.concat(item.posts);
			}
		);
		return list;
	}, [selectedSocialMedia, statisticsData]);

	const getStatisticsData = () => {
		dispatch(
			getStatstics({
				social_handel: selectedSocialMedia.value,
			})
		).then((data) => {
			console.log(data);
			let newData = cloneDeep(statisticsData);
			newData[selectedSocialMedia.label] = data;
			setStatisticsData(newData);
		});
	};

	const compayChip = ({ name, logo_url }) => (
		<div className="comapy-ship-with-image">
			<img src={logo_url} alt="logo" />
			<span>{name}</span>
		</div>
	);
	const data = statisticsData[selectedSocialMedia.label];

	console.log("DATATAA", data);

	const mockText = [
		{
			retailer: "Macy",
			text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
		},
		{
			retailer: "Ralph Lauren",
			text: "Text Ralph",
		},
		{
			retailer: "Tommy",
			text: "Text Tommy",
		},
	];

	return (
		<div>
			<div className="flex flex-horizontal-center">
				<div
					style={{
						color: "#1D1D1D",
						fontSize: "16px",
						fontWeight: 700,
						marginBottom: "10px",
						fontFamily: "Readex Pro",
					}}
				>
					Competitor Analysis
				</div>
			</div>

			{
				<>
					<Grid container>
						<Grid
							item
							xs={12}
							md={12}
							lg={12}
							sx={{
								height: "83px",
								borderRadius: "15px",
								background:
									"linear-gradient(90deg, rgba(216, 230, 250, 0.47) 1.11%, rgba(152, 187, 242, 0.06) 104.9%)",
							}}
						>
							<div className="company-chips">
								{data?.retailer_details &&
									compayChip(data?.retailer_details || {})}
								{data?.retailer_details && (
									<div className="vs">Vs</div>
								)}
								{data?.competitor_details?.map((competitor) =>
									compayChip(competitor)
								)}
								{!data?.retailer_details &&
									!data?.competitor_details &&
									""}
							</div>
						</Grid>
					</Grid>
					<Grid
						container
						sx={{
							marginTop: "40px",
							justifyContent: "space-between",
						}}
					>
						<Grid item xs={4} md={4} lg={4}>
							{<GenAIText data={data?.posts_summary || []} />}
						</Grid>
						<Grid item xs={4.25} md={4.25} lg={4.25}>
							{<BarChart data={data?.audience_growth || []} />}
						</Grid>
						<Grid item xs={3.5} md={3.5} lg={3.5}>
							<Grid container sx={{ margin: "5px" }}>
								<Grid item xs={12} md={12} lg={12}>
									<div>
										<FormControl sx={{ width: "100%" }}>
											<InputLabel
												id="duration-label"
												htmlFor="smd"
												shrink
												sx={{
													color: "#2563EB",
													// fontFamily: "Readex Pro",
												}}
											>
												Social media platform name
											</InputLabel>
											<Select
												size="small"
												fullWidth
												variant="outlined"
												sx={SelectInputStyles}
												displayEmpty
												value={
													selectedSocialMedia.value
												}
												onChange={(e) =>
													setSelectedSocialMedia(
														socialMediaList.find(
															(item) =>
																item.value ===
																e.target.value
														)
													)
												}
												id="smd"
												label="Social media platform name"
												InputLabelProps={{
													shrink: true,
												}}
											>
												{socialMediaList.map(
													(option) => {
														return (
															<MenuItem
																key={
																	option.value
																}
																value={
																	option.value
																}
																sx={
																	DropdownOptionsStyles
																}
															>
																{option.label}
															</MenuItem>
														);
													}
												)}
											</Select>
										</FormControl>
									</div>
								</Grid>
								<Grid
									sx={{
										height: "475px",
										borderRadius: "4px",
										background: "#FFF",
										overflow: "hidden",
										boxShadow:
											"0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
										// overflow: "scroll",
										padding: "18px 30px",
										textAlign: "left",
										marginTop: "20px",
										width: "100%",
									}}
								>
									{data?.stats && (
										<StatsComponents data={data.stats} />
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									color: "#1D1D1D",
									fontFamily: "Readex Pro",
									fontSize: "16px",
									fontStyle: "normal",
									fontWeight: 500,
									lineHeight: "normal",
									paddingLeft: "5px",
									margin: "40px 0 22px 0",
								}}
							>
								Posts
							</div>
						</Grid>
					</Grid>
				</>
			}

			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{topPosts.map((data, index) => (
					<div
						key={index}
						style={{ flexBasis: "24%", marginLeft: "1%" }}
					>
						<SocialMediaPost
							socialMedia={selectedSocialMedia}
							data={data}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const brands = {
	Tommy: {
		Stats: Tommy,
		like: 1,
		topPost: 20,
		eng: "12%",
		view: "300",
		comments: "2002",
		fall: "345",
	},
	Lacosta: {
		Stats: Lacoste,
		like: 1,
		topPost: 20,
		eng: "12%",
		view: "300",
		comments: "2002",
		fall: "345",
	},
	Gucci: {
		Stats: "Icon1",
		like: 1,
		topPost: 20,
		eng: "12%",
		view: "300",
		comments: "2002",
		fall: "345",
	},
	Burberry: {
		Stats: "Icon1",
		like: 1,
		topPost: 20,
		eng: "12%",
		view: "300",
		comments: "2002",
		fall: "345",
	},
	Levis: {
		Stats: "Icon1",
		like: 1,
		topPost: 20,
		eng: "12%",
		view: "300",
		comments: "2002",
		fall: "345",
	},
};

const StatsComponents = ({ data }) => {
	const metrics = [
		"Stats",
		"Like",
		"Top Post",
		"Avg Engagement rate",
		"Avg View Count",
		"Comments",
		"Follower Growth",
	];

	let brands = {};

	data.forEach((item) => {
		brands[item.client_name] = {
			Stats: item.logo_url,
			like: item.like_count,
			topPost: item.top_post,
			eng: item.avg_engagement_rate,
			view: item.avg_view_count,
			comments: item.comments_count,
			fall: item.fall_over_growth,
		};
	});

	const formatData = (num) => {
		if ((num + "").includes("%")) {
			return num;
		}

		return shortNumberFormat(num);
	};

	return (
		<div style={{ display: "flex" }}>
			<div style={{ flexBasis: "10%", marginRight: "5px" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "475px",
						color: "#758490",
						fontFamily: "Readex Pro",
						fontSize: "14px",
						fontWeight: 500,
					}}
				>
					{metrics.map((thisMetric, index) => {
						if (thisMetric === "Stats") {
							return (
								<div
									key={index}
									style={{
										flex: 1,
										color: "#4B5563",
										fontFamily: "Readex Pro",
										fontSize: "16px",
										fontWeight: 600,
										position: "relative",
										top: "10px",
									}}
								>
									{thisMetric}
								</div>
							);
						}
						return <div style={{ flex: 1 }}>{thisMetric}</div>;
					})}
				</div>
			</div>
			<div
				style={{
					flexBasis: "90%",
					overflowX: "scroll",
					overflowY: "hidden",
				}}
			>
				<div
					style={{
						display: "flex",
						width: "100%",
						overflowX: "scroll",
						overflowY: "hidden",
					}}
				>
					{Object.keys(brands).map((thisBrand, idx) => {
						return (
							<div
								key={idx + "a"}
								style={{
									height: "450px",
									background:
										idx % 2 === 0
											? "rgba(239, 195, 126, 0.15)"
											: "rgba(126, 170, 239, 0.15)",
									minWidth: "40px",
									borderRadius: "30px",
									marginRight: "20px",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										height: "475px",
									}}
								>
									{Object.keys(brands[thisBrand]).map(
										(thisMetric, index) => {
											if (thisMetric === "Stats") {
												return (
													<div
														key={index}
														style={{ flex: 1 }}
													>
														<div
															style={{
																background:
																	idx % 2 ===
																	0
																		? "rgba(239, 195, 126, 0.60)"
																		: "rgba(126, 170, 239,0.60)",
																borderRadius:
																	"50%",
																height: "40px",
																width: "40px",
																textAlign:
																	"center",
															}}
														>
															<img
																src={
																	brands[
																		thisBrand
																	][
																		thisMetric
																	]
																}
																alt=""
																style={{
																	height: "15px",
																	marginTop:
																		"10px",
																}}
															/>
														</div>
													</div>
												);
											}
											return (
												<div
													key={index}
													style={{
														flex: 1,
														textAlign: "center",
														color: "#0F264E",

														fontFamily:
															"Readex pro",
														fontSize: "12px",
														fontWeight: 500,
													}}
												>
													{formatData(
														brands[thisBrand][
															thisMetric
														]
													)}
												</div>
											);
										}
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Graphs;

const sentimentalAnalysisData = {
	Twitter: [
		{
			commentsCategory: "Sports",
			positiveWeightage: 20,
			negativeWeightage: 80,
		},
		{
			commentsCategory: "Top hashtags Mentioned",
			positiveWeightage: 68,
			negativeWeightage: 34,
		},
		{
			commentsCategory: "Post with video",
			positiveWeightage: 58,
			negativeWeightage: 42,
		},
	],
	Instagram: [
		{
			commentsCategory: "Fitness",
			positiveWeightage: 80,
			negativeWeightage: 20,
		},
		{
			commentsCategory: "Music",
			positiveWeightage: 34,
			negativeWeightage: 68,
		},
		{
			commentsCategory: "Events",
			positiveWeightage: 59,
			negativeWeightage: 23,
		},
	],
	Tiktok: [
		{
			commentsCategory: "Fashion",
			positiveWeightage: 70,
			negativeWeightage: 10,
		},
		{
			commentsCategory: "Lifestyle",
			positiveWeightage: 68,
			negativeWeightage: 34,
		},
		{
			commentsCategory: "Region",
			positiveWeightage: 42,
			negativeWeightage: 67,
		},
	],
};

const dummyData = {
	retailer_details: {
		name: "Macy's",
		logo_url: null,
	},
	competitor_details: [
		{
			name: "JCPenney",
			logo_url: null,
		},
		{
			name: "Gap",
			logo_url: null,
		},
		{
			name: "Nordstrom",
			logo_url: null,
		},
	],
	audience_growth: [
		{
			client_name: "JCPenney",
			growth: 11770,
		},
		{
			client_name: "Gap",
			growth: 32904,
		},
		{
			client_name: "Nordstrom",
			growth: 234771,
		},
	],
	engagement_per_post: [
		{
			client_name: "JCPenney",
			engagement: [
				{
					date: "Mon, 25 Mar 2024 22:51:18 GMT",
					count: 2775,
				},
				{
					date: "Thu, 21 Mar 2024 21:41:34 GMT",
					count: 1696,
				},
				{
					date: "Sun, 24 Mar 2024 21:37:34 GMT",
					count: 1639,
				},
				{
					date: "Fri, 22 Mar 2024 20:16:11 GMT",
					count: 1355,
				},
				{
					date: "Sat, 23 Mar 2024 21:52:03 GMT",
					count: 3612,
				},
			],
		},
		{
			client_name: "Gap",
			engagement: [
				{
					date: "Tue, 26 Mar 2024 15:30:19 GMT",
					count: 11803,
				},
				{
					date: "Fri, 22 Mar 2024 15:00:23 GMT",
					count: 0,
				},
				{
					date: "Fri, 22 Mar 2024 19:11:55 GMT",
					count: 0,
				},
				{
					date: "Thu, 21 Mar 2024 10:50:48 GMT",
					count: 0,
				},
			],
		},
		{
			client_name: "Nordstrom",
			engagement: [
				{
					date: "Fri, 22 Mar 2024 21:00:47 GMT",
					count: 60460,
				},
				{
					date: "Sat, 23 Mar 2024 02:21:06 GMT",
					count: 70348,
				},
				{
					date: "Sun, 24 Mar 2024 17:13:01 GMT",
					count: 23464,
				},
				{
					date: "Tue, 26 Mar 2024 16:01:03 GMT",
					count: 0,
				},
				{
					date: "Mon, 25 Mar 2024 18:56:24 GMT",
					count: 3979,
				},
				{
					date: "Wed, 27 Mar 2024 18:27:35 GMT",
					count: 1326,
				},
				{
					date: "Sat, 23 Mar 2024 15:14:14 GMT",
					count: 17737,
				},
				{
					date: "Fri, 22 Mar 2024 01:00:58 GMT",
					count: 7493,
				},
				{
					date: "Thu, 21 Mar 2024 19:44:18 GMT",
					count: 44465,
				},
			],
		},
	],
	stats: [
		{
			client_name: "JCPenney",
			logo_url: null,
			like_count: 130,
			top_post: 5,
			avg_engagement_rate: 327.29,
			avg_view_count: 2775,
			comments_count: 7,
			fall_over_growth: 0,
		},
		{
			client_name: "Gap",
			logo_url: null,
			like_count: 2067,
			top_post: 4,
			avg_engagement_rate: 433.44,
			avg_view_count: 11803,
			comments_count: 43,
			fall_over_growth: 0,
		},
		{
			client_name: "Nordstrom",
			logo_url: null,
			like_count: 1269,
			top_post: 9,
			avg_engagement_rate: 1758.25,
			avg_view_count: 60460,
			comments_count: 70,
			fall_over_growth: 0,
		},
	],
	top_posts: [
		{
			client_name: "JCPenney",
			posts: [
				{
					logo_url: null,
					client_name: "JCPenney",
					timedate: "Mon, 25 Mar 2024 22:51:18 GMT",
					caption:
						"Don't get eggs-istential about Easter prep this Sunday 🐰 @melodyinthemaking nailed it with these easy tips. Shop everything you need to set the scene at the link in our bio ✨",
					image: "https://storage.googleapis.com/instagram-posts-image/jcpenney/jcpenney_2024-03-25_9384.png?Expires=1711605076&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=Kw%2Fz7vEiiChZJGdjK%2FdLMY%2B3YX3lnSx7uSFumuQ3qzA3o3msNEpb%2BpAjrpoCH4CPVY50Kyq4uIxxehVWuJJxMhC0p5kGyU11nni1VkfYc0%2BxceMPQzIzrL7B92P2E0MgR7%2B2i%2BMNBpxmQ%2Bfp5Fi80I7kBUruxBoLjp2xFfZq89HvIjpwHM8lYpyrnGYmUZWq3FbY5WkTk%2F9TdYbC9MNh96SfEqu7vEd%2FLmWMEKxmASekWyHJ0iM7KkrLxet6tQao2YO4CnWn2n8X7d2MoZDrvkTkqSxWCnLygoi6lQVx2p4RuuWijH9Augq5T8hbnW6Qa%2BqJW9VoPZJVgmupwmQ%2Fxw%3D%3D",
					total_engagement: 2912,
					like_share: 130,
					comment: 7,
					views_count: 2775,
				},
				{
					logo_url: null,
					client_name: "JCPenney",
					timedate: "Thu, 21 Mar 2024 21:41:34 GMT",
					caption:
						'Happy National Fragrance Day 🌸 Celebrate with our "it-girls" of fragrance. Plus, our Spring Beauty Event is here! Get 20% off full-size fragrances through Saturday. Shop in-store or at the link in our bio ✨\n\n🎥: @carolinemstern',
					image: "https://storage.googleapis.com/instagram-posts-image/jcpenney/jcpenney_2024-03-21_8063.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=WxfaiKpnxSQeaz9vWeg0%2Fdb55qHuUS3569QYLf3aepiIDtkKzL7DOBQtFCQ1XfJwCfq4yAJySu9LG%2BzyHWI47xcWeRZqoh%2FbmXkWH7rE7BrwRI1ExUn4k8OR%2FFbFSGnBDFzZPJV3LtZnG4%2F7D0HTz%2F%2Fvqgg%2B3tVUYOyvwr8QhZCjYtTK7yUsaZfV3FMDFZJK5NwNij8taPOEH9OGuzPz93jeifaUtE%2BtVxRkdr0aoi5HDAzbvaS45jZJxSKTKLJ60Uq6p%2FH9zhNGrn9nGESEBlH99%2BnS3fQMEmguAYPW5jF1oZU5bKghIJq0wU728Hz%2B9yrQ8RRlsuN8KYbGIN2pHQ%3D%3D",
					total_engagement: 1874,
					like_share: 172,
					comment: 6,
					views_count: 1696,
				},
				{
					logo_url: null,
					client_name: "JCPenney",
					timedate: "Sun, 24 Mar 2024 21:37:34 GMT",
					caption:
						"Perfect haircare products 🤝 Textured hair goals ✨\n🎥: @tayloranise",
					image: "https://storage.googleapis.com/instagram-posts-image/jcpenney/jcpenney_2024-03-24_8079.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=fA6UMFIrtQniLGOUmUw1fNzyBbMIAY%2FS126reDpPr7ox3kWTGYzgb6ilFbT5i4yR4padMXjPbF9Y5AqTpaYlcWfYnsgJsXk%2BJs5Oa38Fmn9ejLr4pYhvknfGCH0AUWbqklGnzfuyxIlTuk0I2zKX5SCIGwZt%2Bm7HGp1t1VwGZ3RcEh%2Fq2cpFrqwZG2bDakzIqJV2zG%2FPv73b%2FXtwowlIz9Ncl%2F8GjPBKVSPAHqtXJGN99%2FELj%2B6ByEEB7t%2BLjhtWs6mKnJ60N32JwCXbd5HfKl%2BqrzMdrJ%2F%2BikX%2FrOGjK2yMftTlsym%2BMwyZtHoVYbrMUTA2rPf7TCaXsYcT4qwtug%3D%3D",
					total_engagement: 1758,
					like_share: 118,
					comment: 1,
					views_count: 1639,
				},
				{
					logo_url: null,
					client_name: "JCPenney",
					timedate: "Fri, 22 Mar 2024 20:16:11 GMT",
					caption:
						"Travel checklist: Cargo pants for stashing essentials ✅ Layers for all weather ✅ A crossbody for hands-free exploring ✅ Shop our travel fashion edit at the link in bio ✨",
					image: "https://storage.googleapis.com/instagram-posts-image/jcpenney/jcpenney_2024-03-22_9336.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=S%2Fn7EaZ5dOz8VzXXjxwze%2BQ4c7WF6D2fqnUF6%2F%2FpnXVtuEC2tEJLEUu%2FhfmHtgLOafbIPWrjGdWrfgg0BOcPbH1nMUlOi0KzLXA1Hq1NuYlwlW02uoDBtckxVL1z09%2BENHC9Sx4rez6xdX%2FzxmsXLQAiyoosaKODlk3Yasj23lFjft0chTdAcH0R%2FVUaMla1tN%2BLLi6ThTusdO9hWQ0zo5iUAZ9GjUuW0skAaDcpLrWlKxqYykAA8w7UhMY4VIXodcIUoQ86ngmUQ8yUkfjdwz8crwjk3AwmHEUlIe2BkeR9iXso3qgdPpxU28VVAD9jFZQyb5JpJlbjb7USC4cdJw%3D%3D",
					total_engagement: 1463,
					like_share: 108,
					comment: 0,
					views_count: 1355,
				},
				{
					logo_url: null,
					client_name: "JCPenney",
					timedate: "Sat, 23 Mar 2024 21:52:03 GMT",
					caption:
						"We admit it, we like nice things 😌 Dive into our Billion Dollar Jewelry Sale and indulge in deals on everything from fine jewelry to gemstones. Treat yourself – guilt free 💍 Shop at the link in our bio ✨",
					image: "https://storage.googleapis.com/instagram-posts-image/jcpenney/jcpenney_2024-03-23_3320.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=GtWyV%2BWMPAxQoINoVUhihkjNb7TzYDwHUfZxnil6QbnWHJ7THQEHhn3ZTUCIO%2BAezv1B2xYeAcxWtrywAUtSzDMfjzxyV343Sd%2Fm78GWL%2FUKKYgfkafw7E3R4fN%2B1nWXf5xAsiXXIivcXUNadREq7c2Gsglzkb4IFAqRqV1HP08hpEbMH8s2weTQmarWGEeM7C1ssPoO7XuhxtirydTnVxGmdkXGKxEXmyHE6oPpJEo9ZPNs1yYE6VKpthwA3v%2BD00yf6jLetQcGpTxe7tuLMVYmf%2Fv19IjMwhLK%2FN6WkRPjD%2BSYonXRap1eOmLySfygqMIWNtul3qNFUJmfo9%2BBvw%3D%3D",
					total_engagement: 3763,
					like_share: 147,
					comment: 4,
					views_count: 3612,
				},
			],
		},
		{
			client_name: "Gap",
			posts: [
				{
					logo_url: null,
					client_name: "Gap",
					timedate: "Tue, 26 Mar 2024 15:30:19 GMT",
					caption:
						"The Making of: Linen moves. A short film documenting how Tyla, Jungle, and a family of dancers created a movement. In this never-before-seen vignette, the process of merging music, style, and dance unfolds. Rehearsals. Interviews. The final cut. All in Gap. \n\nDirected by @duncan",
					image: "https://storage.googleapis.com/instagram-posts-image/gap/gap_2024-03-26_4732.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=P5eTjcJlVdrCcxlrh3n3X8iEq%2Fiks7W5XVo7Ka%2Ft560cHjOAfaddahYqYonrczHUwcaOA0CWDbKiBYpltgGwFJF%2BIbWGVH2Wm7EDQeFQWILxQY6sxBMJWh%2BssIQGaL9289KwPTa88AHJv%2BiKhc4mRZb%2BhcifgGf0s4TIdZOwt3YIBgY2rYW4%2B%2BhjFpVR9hKMTPJJDOx8x31%2FSEkGeWzXioG9Sfva7Ec4D2ek3eQ4OGRB%2FkW6ATGAQRZxHXkEcF5IcZqDYYVM3s87YtVA7cz5fZdGQw%2FvKFd7fuK%2BDURjRAUQSFq9E4Vt8UDRzKzeLC1A4ojE5M9IGDE3VFIP04Dh4Q%3D%3D",
					total_engagement: 13913,
					like_share: 2067,
					comment: 43,
					views_count: 11803,
				},
				{
					logo_url: null,
					client_name: "Gap",
					timedate: "Fri, 22 Mar 2024 15:00:23 GMT",
					caption:
						"• NOW LIVE\n• PALACE GAP\n• LIFE GOES FAST REAL QUICK",
					image: "",
					total_engagement: 3799,
					like_share: 3735,
					comment: 64,
					views_count: 0,
				},
				{
					logo_url: null,
					client_name: "Gap",
					timedate: "Fri, 22 Mar 2024 19:11:55 GMT",
					caption:
						"• WE’VE \n• GOT \n• POP! \n• PALACE FAMILY\n• IN PALACE GAP\n• IN POP MAG\n• 50TH ISSUE 😍",
					image: "",
					total_engagement: 1994,
					like_share: 1963,
					comment: 31,
					views_count: 0,
				},
				{
					logo_url: null,
					client_name: "Gap",
					timedate: "Thu, 21 Mar 2024 10:50:48 GMT",
					caption:
						"♻️\n• PALACE GAP\n• LAUNCHING FRIDAY MARCH 22ND\n• ACROSS PALACE AND GAP ONLINE & IN-STORE\n• CHECK ONLINE \n• FOR MORE DETAILS",
					image: "",
					total_engagement: 13198,
					like_share: 13059,
					comment: 139,
					views_count: 0,
				},
			],
		},
		{
			client_name: "Nordstrom",
			posts: [
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Fri, 22 Mar 2024 21:00:47 GMT",
					caption:
						"Come hunt for the perfect spring wedding guest dress @nordstrom with me! I’m a ride or die Nordstrom girl, they carry all of my favorite brands in one place which makes finding the perfect piece almost toooo easy. I have to know which one was your fav? I feel like the All Saints one would be a dress up closet staple, but man that red dress is a show stopper. \n\n#midsizefashion #size14 #reddress #nordstrom #springdress #weddingguesstdress #ad #nordstrompartner #springstyle #midsize #midsizestyle",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-22_8927.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=PgrB2xeS33akPYg2%2F%2BDyEO7QHo098IOj6mDCFiEI%2FLlmd9%2B0os8ONyxqJeo4gJzkZpjrLBqRK%2Fdmqglen%2Fg%2FLiQokifN9nNqMkf5B1G4IUzBtx1kRbhohsKRqTqCcDxvNxUNLo2tFo1FcsslicIV1mbGqJMtXEWblp47fID0mTJ1MnFA93gIdOFy5ADHejHsXj%2BKbRNpNXaYKGoBV4YoyZU%2BJ1UrYt3oed7bQI6eTzLydJ6IrUWxgaL%2BmMn6C1YrKhp3nnF9cPwakTxU7RPfMP%2FNnQXCjZ0mAj7fV5GnihlXhRE9SdJJxz2NcZbIsXmtzNAorrgO08uxcUh75%2Bx%2BNA%3D%3D",
					total_engagement: 61799,
					like_share: 1269,
					comment: 70,
					views_count: 60460,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Sat, 23 Mar 2024 02:21:06 GMT",
					caption:
						"Have you ever tried the sandwich method? @judyxkam shows off our new favorite wardrobe hack.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-23_1277.png?Expires=1711605077&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=eBP%2Fqp1JFmEN6URXYTS7R6c30wkRwByrSfursV3G5IWUcIV5qhPGbMSkiE0gcbF%2FJkltwJfAxLmtLBrDVvq%2BsZ6eLOnhjKp0rZIdQfl8AeEk5Hxct0VhPtv9rZX%2BJrfERWfHR6BtYbTCXWLUu0PnbKh0dVIe3XKLQbz%2Bf2GNFlYlwwr2nRD0qyDbAn9e%2Fkhz4z247hs3ilOF3nIyUzdvBpV1USioXgbdPWlHUhi4GrTYMWrl3wd8lpt2VrPEy7Zt07cUYnlVGX3CvnU7L0r1MSdHxAaIkTkf4nSPj%2FQJhEClkrYRbS9fXliG%2BpV9w6d2GBSNvL%2BSHCUZSseqeDUURg%3D%3D",
					total_engagement: 71427,
					like_share: 997,
					comment: 82,
					views_count: 70348,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Sun, 24 Mar 2024 17:13:01 GMT",
					caption:
						"London calling: British heritage brand @libertylondon has landed at @nordstrom, including a special installation at Center Stage in @nordstromnyc 💜 Discover the collection online and IRL at select Nordstrom locations for a limited time. Shop or plan your visit at the link in bio.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-24_5932.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=AEYUvi5XmAuQjFlrfTzPx4NGtfJ%2FjteKbO5PlT%2FcK47VeQh0vi6GJK%2Brz9jy6%2FAKfkP9D6UqKCQP5njV7bVhJ4gM5tIyCwknChmG9vT%2B9Vg5pNywFZKx0SdzOqaiRjLQ%2Fb122z3V2oMxHfwXGMZKYPJ7vkevYqVjO58MpKFrDqMEjvvIfiea4BQ2PtMdNL5K6kO0Gh20kADk3QVkADnR7essybNmR1THD07RgPIr1DMDfl45Ue%2F0bKGZkDupSs5QMT33MVWL9XLUseFTWoQMvQpfNlDf1qEG2QqF7ZYvR4d%2B2m6HbTQy9G1PtQfwqGhMu6Jd3lmOzZL86wwXs362OQ%3D%3D",
					total_engagement: 24263,
					like_share: 773,
					comment: 26,
					views_count: 23464,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Tue, 26 Mar 2024 16:01:03 GMT",
					caption:
						"New from @nike: Air Max Dn is here. Spotted on the streets at London Fashion Week, this highly anticipated sneaker merges futuristic design and dynamic cushioning for incredible support and style. Get yours now at link in bio!",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-26_4247.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=E4Uq3Lmt3jeNhLSD5aqdWVoIKSdDvNgu45B%2FUMl3lpcyiQXKLUdaejpupdNOrIPnUyPiOJV4nBgrEViJKGMxYVZ3%2BUR%2FDApviFnCVIA2JjcLFD0lggbau3JsmmNJTdVwC3HwwQaYD76rgE6f5JsPL9lNdc2YUkPbB67EtFAtFMrvzw2RWhrsQrFz3mwv%2F8lA69Ig%2BWB4PoIaTUc7nl9jMzKRudoJGNZCgaEPemM1S6azbVwrM1y211C5G1LMwAF%2F4uz%2Fvk3gIT%2FCIBMi%2Fh6Lb5JNTLbTAYEAqqGLjqas28r%2FCE9u2XWeCzc99A%2Fzc1e8MKSTCECizrLHUUg%2B2pflwg%3D%3D",
					total_engagement: 267,
					like_share: 263,
					comment: 4,
					views_count: 0,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Mon, 25 Mar 2024 18:56:24 GMT",
					caption:
						"Soak up the sun in style with these must-have spring essentials! ☀️🌺 ​\n​",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-25_7810.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=ba%2FrVVwjLDLw%2BaxbhiAyAkFxTjz3%2FmVNE1mUcFx2pb0FiCKRdOjfqd%2B%2F5iPTCtke70qNANz5s9Rb4l3zn823S9HkwE7DZpj3Ow98CTroaoGaabzz6YuqA0GekEUFC0XJV53bfjH9%2BVvgNC5i%2FJumiAHapoEXdbkq72m5jJn%2F%2B10EUf%2FrtAG%2FPbZ%2FlpcERfoKpmlu2GQlchY6nEFkwXjR501QPR4F32tb9BLmSku%2Fl66yWta0UAqtb0idFW5iu5xYNygU%2Ffeh73Sha1o%2Boma3VLEr5C9iKZk9za1sJo9PTAQ9XIxgpQqbtTY1vxArpdlTJT3DhhbNy46aaQCfU7hUYA%3D%3D",
					total_engagement: 4252,
					like_share: 272,
					comment: 1,
					views_count: 3979,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Wed, 27 Mar 2024 18:27:35 GMT",
					caption:
						"The ultimate vacation wardrobe always includes a matching set. Which style will you be packing for your next getaway? Discover more vacation essentials at the link in bio.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-27_3695.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=Xn%2B8AVNAe5%2Fh%2F6fT3%2FsMQLbaLt%2F91o5ZUaSEu5YmcVmzbb89VYLhbAI4IFCEHTZqF3VtpcfbvKbSA1fLE%2FwMqO%2FxjyJ2XnZ3ReRh6E1WGpc7Z6VJPhpOlqZCmgThKT42%2Fo4aGgg6UnEj%2B3LdoQE3KOkHCUtIe7lGHVZ8syUlmvxoUdHJosqAss5tqIqvqbWT6vyp2LRVDT%2FIRP%2FKZTWsnVDZUe2FngPSYZtmMkSi8sSKL8neeSduPuvuHrkmWMjDf%2FvhY0Xsaa9D1XhW7WTDkfcLrnrB7GbCafmWumC05WkK3rdXxELjXNuiXch7wf3le0zPHziQeNw%2BMCvSkb4l8Q%3D%3D",
					total_engagement: 1399,
					like_share: 71,
					comment: 2,
					views_count: 1326,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Sat, 23 Mar 2024 15:14:14 GMT",
					caption:
						"Twin models-turned-designers Amalie and Cecilie Moosgaard of @liestudio_ chat with us about the ethos behind their jewelry brand, the secret to that polished yet effortless Danish style and the women who have inspired them along the way—read the full story on The Thread and shop Lié Studio now at Nordstrom.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-23_7884.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=dbSwB2s0xMU%2F0U49svmXgTsud4PDzRb02%2Bal0l0jlXP2G7P%2FBbqVr57nZ1GfEz%2Bi%2FrzHeabN%2B1jldoIQ0mP1Bv1GQ3tziDUYzocMKiRPS35ZhWG%2FBv9wk%2BUp68x9T5VDZ0fOLWqRlg9u%2FV5%2B0qu9EkK8hUxff%2FV8B%2BfeIf1T5RW1Pig51FgsFiK6wIkqczK7yTGb4PGJ2zoxquS8B23%2BCCSqexmDCEJDFCHqK1VKRmcOockkmRgu0%2FdQTl9GBV%2FClGWCgfOv8BsWKv39kvke1n8uqbcCHyOKdIo8g4Os%2Bv%2BIAu3DMzjKdO%2F6xCNIE3Gb3XcumbOlADmOinGFTZGU7Q%3D%3D",
					total_engagement: 18187,
					like_share: 418,
					comment: 32,
					views_count: 17737,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Fri, 22 Mar 2024 01:00:58 GMT",
					caption:
						"We asked everyone at our spring photoshoot to try on these CELINE sunnies proving that EVERYONE looks good in a pair of CELINE sunnies.😎 Head to the link in bio to shop these sunglasses and more.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-22_2868.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=AkxVKTDFiSViGUjD1OZK6AkLZogPeu110SWLXx8PsjPPLkRX8K6jwMuSuNOyPpKbFd5db7uu7w%2Bwqp0okxsI2pDhEEUEcNVir3dj34uQPDh%2FmSrEW2AfpkEHY9vZvilXC%2B2EpT542zkAPaujixoeayklXj%2BkCsF2hL0Xd2NdzgPM961JMReoX5eZTppwoM0d0LqN75BPApQgH8upGqHBDYeCtT0plcZopZaf5S%2F4i4QNzksiLyQ3fHuj8TbS1vG%2FYwAdYmgGoGvBPCdjVOTIaSITHmc%2B8XmthnhMPRAsgcW74T%2FinGvxzfZYkov5VlsWObtD%2BGjQ3VZcsW5vFgD1LA%3D%3D",
					total_engagement: 8015,
					like_share: 488,
					comment: 34,
					views_count: 7493,
				},
				{
					logo_url: null,
					client_name: "Nordstrom",
					timedate: "Thu, 21 Mar 2024 19:44:18 GMT",
					caption:
						"✨Stylist Tips✨​\n\nWide-leg denim is the trend to try right now—our expert @styledbymyt shares 3 ways to wear it.​\n\nGet the denim of your dreams with personalized outfit ideas sent right to your inbox—it’s free! Request a look from our stylists at the link in bio.",
					image: "https://storage.googleapis.com/instagram-posts-image/nordstrom/nordstrom_2024-03-21_9339.png?Expires=1711605078&GoogleAccessId=competitor-intelligence-dev%40datamart-352005.iam.gserviceaccount.com&Signature=eRLB%2FPt43UEL%2B3wGTMxRkOoZEqIQtG2OlLZr25kWEVAJKC15QZ8THWySanweteX2PyjXJqUBLnXYZfCFO4vN7mvZIiUJ7bOirfAhx4l3fJ7gPlgDfNvDipLbZgF%2FPE4uwOYsKi9y%2B9KVPycbBMnxb0aU16VkqN0Es6eFlEpyt90CJbDai%2F8lfMdt8%2BxKOZUWj5xDEyBOE6zgSd2EKu%2BVtS9kk8dkbO8dDOpifUaSdUHBYfDFBHXZigLg4sEsGwkBsnVpIqqaXgXWXtoR5KRLzHHY2AC5dkrzQ0zMGCraP%2Fro%2FGxfLPYUh51FkpUKEvN%2B1gkhjjnfcqSto5UXPn%2FGzg%3D%3D",
					total_engagement: 45162,
					like_share: 662,
					comment: 35,
					views_count: 44465,
				},
			],
		},
	],
};
