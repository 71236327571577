import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Select, MenuItem } from "@mui/material";
import { ChartSelectStyles } from "../../CommonStyles";

const BarChart = ({ data }) => {
	const chart = useRef();

	const options = {
		chart: {
			type: "column",
		},
		title: {
			text: "",
		},
		xAxis: {
			gridLineColor: "#F1F1F1", // Light gray color for grid lines
			categories: data.map((item) => item.client_name),
			lineColor: "#F1F1F1", // Change x-axis color
			gridLineWidth: 1, // customizing x-axis grid lines
			title: {
				text: "Competitors",
				margin: 15, // Adjust margin as needed
				style: {
					fontFamily: "Readex pro",
					fontSize: "14px",
					color: "rgba(125, 125, 125, 0.50)",
				},
			},
			labels: {
				style: {
					fontFamily: "Readex pro",
					color: "#7D7D7D",
					fontSize: "10px", // Font size for x-axis labels
				},
			},
		},
		legend: {
			enabled: false, // Remove legend
		},
		yAxis: {
			gridLineColor: "#F1F1F1", // Light gray color for grid lines
			lineWidth: 0, // Remove y-axis line
			labels: {
				style: {
					fontFamily: "Readex pro",
					color: "#7D7D7D",
					fontSize: "10px", // Font size for x-axis labels
				},
			},
			title: {
				text: "",
			},
			gridLineWidth: 1, // customizing y-axis grid lines
		},
		credits: {
			enabled: false, // Remove legend
		},
		plotOptions: {
			column: {
				borderRadius: 8, // Add border radius to the column
				borderWidth: 0, // Remove border
				colorByPoint: true, // Enable color by point
				colors: [
					// Apply gradient colors
					{
						linearGradient: {
							x1: 0,
							x2: 0,
							y1: 0,
							y2: 1,
						},
						stops: [
							[0, "#B0CDF6"],
							[1, "white"],
						],
					},
				],
			},
		},
		series: [
			{
				name: "Data",
				data: data.map((item) => item.growth),
				color: "#B0CDF6", // custom bar color
				borderWidth: 2, // custom bar width
			},
		],
	};
	return (
		<div
			style={{
				margin: "5px",
				borderRadius: "4px",
				background: "#FFF",
				boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
				padding: "28px 45px 15px 45px",
				height: "540px",
			}}
		>
			{data.length > 0 && (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "baseline",
					}}
				>
					<div
						style={{
							fontFamily: "Readex Pro", // Set custom font family
							fontSize: "16px",
							color: "#4B5563",
							fontWeight: 500,
							marginBottom: "20px",
						}}
					>
						Followers Growth
					</div>
					{/* <div>
					<Select
						sx={ChartSelectStyles}
						size="small"
						fullWidth
						variant="outlined"
						displayEmpty
						value={"Last 1 week"}
						onChange={() => {}}
						id="duration"
						label=""
						InputLabelProps={{
							shrink: true,
						}}
					>
						{[{ value: "Last 1 week", label: "Last 1 week" }].map(
							(option) => {
								return (
									<MenuItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</MenuItem>
								);
							}
						)}
					</Select>
				</div> */}
				</div>
			)}
			<div>
				{data.length > 0 && (
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
						ref={chart}
					/>
				)}
			</div>
		</div>
	);
};

export default BarChart;
