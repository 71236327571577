import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "./apiReducer";

export const allStrategiesSlice = createSlice({
	name: "allStrategies",
	initialState: {
		allStrategies: [],
		selectedStrategyDetails: [],
	},
	reducers: {
		setAllStrategies: (state, action) => {
			state.allStrategies = action.payload;
		},
		setStrategyDetails: (state, action) => {
			state.selectedStrategyDetails = action.payload;
		},
	},
});

export const { setAllStrategies, setStrategyDetails } =
	allStrategiesSlice.actions;

// An syncronous action creator that returns a async function isstead of object as in case of synchronous action creators.
export const getAllStrategies = () => async (dispatch) => {
	const response = await callApi(dispatch, "get", "list_all_strategies");
	if (response) {
		dispatch(setAllStrategies(response));
	}
};


export const refereshAllStrategies = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "post", "refresh_reports", filters);
	if (response) {
		dispatch(setAllStrategies(response));
	}
};

export const getStrategyDetails = (strategy_id) => async (dispatch) => {
	const response = await callApi(
		dispatch,
		"get",
		`get_strategy_details?strategy_id=${strategy_id}`
	);
	if (response) {
		const { report_json } = response[0];
		let formattedData = [];
		report_json.forEach((thisData) => {
			let obj = {
				...thisData,
				instagram: {
					...thisData.instagram,
					images: thisData.instagram.images.map((url) => ({
						url,
						validated: true,
					})),
					validated:
						thisData.instagram.images.length === 0 &&
						thisData.instagram.text === ""
							? false
							: true,
				},
				news: {
					...thisData.news,
					images: thisData.news.images.map((url) => ({
						url,
						validated: true,
					})),
					validated:
						thisData.news.images.length === 0 &&
						thisData.news.text === ""
							? false
							: true,
				},

				tiktok: {
					...thisData.tiktok,
					images: thisData.tiktok.images.map((url) => ({
						url,
						validated: true,
					})),
					validated:
						thisData.tiktok.images.length === 0 &&
						thisData.tiktok.text === ""
							? false
							: true,
				},

				// twitter: {
				//  ...thisData.twitter,
				//  images: thisData.twitter.images.map((url) => ({
				//      url,
				//      validated: true,
				//  })),
				//  validated:
				//      thisData.twitter.images.length === 0 &&
				//      thisData.twitter.text === ""
				//          ? false
				//          : true,
				// },

				email: {
					...thisData?.email,
					images: thisData?.email?.images.map((url) => ({
						url,
						validated: true,
					})),
					validated:
						thisData?.email?.images.length === 0 &&
						thisData?.email?.text === ""
							? false
							: true,
				},
			};
			formattedData.push(obj);
		});
		let details = {
			...response[0],
			strategy_data: formattedData,
		};
		console.log({ details });
		dispatch(setStrategyDetails(details));
	}
};

export const exportStrategy = (payload) => async (dispatch) => {
	const response = await callApi(dispatch, "post", "export_pdf", payload);
	if (response) {
		return response;
	} else {
		return false;
	}
};

export default allStrategiesSlice.reducer;
