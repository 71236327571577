import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, Divider, Grid, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageAnalysis from "../../Assets/Image-Analysis.png";
import ImageList from "../../CommonComponents/ImageStack";
import {
	ButtonGroupStyles,
	ContainedButtonStyles,
	WhiteButtonStyles,
} from "../../CommonStyles/";
import "./style.css";
import PDFViwer from "./PDFViwer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
	getStrategyDetails,
	exportStrategy,
} from "../../Reducers/allStrategiesReducer";
import { setShowSnackBar } from "../../Reducers/apiReducer";
import NoImage from "../../Assets/NoImage.png";

const StyledButton = ({ idx, active, config, onClick }) => {
	const { label, validated } = config;
	const labelStyles = {
		color: active === idx ? "#fff" : "#3B82F6",
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: "bold",
		lineHeight: "normal",
		marginRight: "6px",
	};
	const bgColor = active === idx ? "#3B82F6" : "#fff";
	return (
		<Button id={`${label}-${idx}`} sx={ButtonGroupStyles(bgColor)} onClick={() => onClick(idx)}>
			<span style={labelStyles}>{label}</span>
			{validated ? (
				<CheckCircleIcon
					fontSize="small"
					sx={{ color: "#00afa0", zIndex: "1000" }}
				/>
			) : (
				""
			)}
		</Button>
	);
};

const TextAreaComponent = ({
	data,
	handleValidateMedia,
	handleChangeMediaText,
	handleValidateImage,
	type,
}) => {
	const noUpdates = data.text === "" && data.images.length === 0;
	let border = "1px solid #C8CED0";
	let boxShadow = "";
	let color = "#B3B3B3";
	let labelColor = "#B3B3B3";
	if (!noUpdates && data.validated) {
		border = "1px solid #3485FE";
		boxShadow = "0px 0px 4px 0px rgba(52, 133, 254, 0.41)";
		color = "#1A203D";
		labelColor = "#758490";
	}
	const textAreaStyles = {
		border,
		width: "100%",
		borderRadius: "2px",
		background: "#FCFCFD",
		boxShadow,
		padding: "2vh 2vh",
		color,
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontStyle: " normal",
		fontWeight: 400,
		lineHeight: "19.6px",
		height: "calc(20vh - 20px)",
		resize: "none",
	};

	const labelStyles = {
		color: labelColor,
		fontFamily: "Readex Pro",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
	};

	const noDataText = `Oops! ${type} Updates are unavailable.`;

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={labelStyles}>{`${type} Updates`}</div>
				{!noUpdates && data.validated ? (
					<RemoveCircleIcon
						id={`remove-${type}`}
						onClick={handleValidateMedia}
						sx={{
							color: noUpdates ? "#d9d9d9" : "#3B82F6",
							fontSize: "30px",
							disabled: noUpdates,
							pointerEvents: noUpdates ? "none" : "all",
						}}
					/>
				) : (
					<AddCircleIcon
						id={`add-${type}`}
						onClick={handleValidateMedia}
						sx={{
							color: noUpdates ? "#d9d9d9" : "#3B82F6",
							fontSize: "30px",
							disabled: noUpdates,
							pointerEvents: noUpdates ? "none" : "all",
						}}
					/>
				)}
			</div>
			<textarea
				style={{
					...textAreaStyles,
					fontSize: noUpdates ? "20px" : "14px",
				}}
				rows={10}
				value={noUpdates ? noDataText : data.text}
				disabled={noUpdates || !data.validated}
				onChange={(e) => handleChangeMediaText(e.target.value)}
			/>
			{!noUpdates && data?.images ? (
				<ImageList
					initialImages={data.images}
					onRemove={(images) => handleValidateImage(images)}
					validated={data?.validated}
				/>
			) : (
				<img
					src={NoImage}
					alt=""
					style={{ height: "12vh", marginTop: "0.5vh" }}
				/>
			)}
		</div>
	);
};

const Analysis = (props) => {
	const location = useLocation();
	useEffect(() => {
		const strategy_id = location.hash ? location.hash.substring(1) : null;
		props.getStrategyDetails(strategy_id);
	}, []);
	const strategyDetails = useSelector(
		(state) =>
			state.allStrategiesState.selectedStrategyDetails.strategy_data
	);
	const strategyDuration = useSelector(
		(state) => state.allStrategiesState.selectedStrategyDetails.duration
	);
	const strategyId = useSelector(
		(state) => state.allStrategiesState.selectedStrategyDetails.strategy_id
	);

	useEffect(() => {
		setData(strategyDetails);
	}, [strategyDetails]);
	const navigate = useNavigate();
	const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);
	const [pdfLink, setPdfLink] = useState("");

	const [data, setData] = useState([]);
	const [active, setActive] = useState(0);

	const escapeListener = useCallback((e) => {
		if (e.code === "Escape") {
			setIsPDFViewerOpen(false);
		}
	}, []);

	useEffect(() => {
		if (isPDFViewerOpen) {
			document.addEventListener("keydown", escapeListener);
		} else {
			document.removeEventListener("keydown", escapeListener);
		}
	}, [escapeListener, isPDFViewerOpen]);

	const handleClick = (id) => {
		setActive(id);
	};

	const handleValidateBrand = () => {
		let newData = cloneDeep(data);
		newData[active].validated = !newData[active].validated;
		setData(newData);
	};

	const handleValidateMedia = (media) => {
		console.log("Validating Media", media);
		if (data[active][media]) {
			let newData = cloneDeep(data);
			newData[active][media].validated = !data[active][media].validated;
			setData(newData);
		}
	};

	const handleValidateImage = (media, list) => {
		if (data[active][media]) {
			let newData = cloneDeep(data);
			newData[active][media].images = list;
			setData(newData);
		}
	};

	const handleChangeMediaText = (media, text) => {
		let newData = cloneDeep(data);
		newData[active][media].text = text;
		setData(newData);
	};

	const handleViewMore = () => {
		navigate(`/graphs`);
	};

	const exportHandler = () => {
		const report_json = [];
		let oddImages = false;
		data.forEach((thisBrand) => {
			let thisInstagram = null;
			let thisNews = null;
			let thisTiktok = null;
			// let thisTwitter = null;
			let thisEmail = null;
			if (thisBrand.validated) {
				if (thisBrand.instagram.validated) {
					let images = [];

					thisBrand.instagram?.images.forEach((thisImage) => {
						if (thisImage?.validated) {
							images.push(thisImage.url);
						}
					});
					if (images.length > 1 && images.length % 2 !== 0) {
						oddImages = true;
					}
					thisInstagram = {
						...thisBrand.instagram,
						images,
					};
				}
				if (thisBrand.news.validated) {
					let images = [];
					thisBrand.news?.images.forEach((thisImage) => {
						if (thisImage?.validated) {
							images.push(thisImage.url);
						}
					});
					if (images.length > 1 && images.length % 2 !== 0) {
						oddImages = true;
					}
					thisNews = {
						...thisBrand.news,
						images,
					};
				}

				if (thisBrand.tiktok.validated) {
					let images = [];
					thisBrand.tiktok?.images.forEach((thisImage) => {
						if (thisImage?.validated) {
							images.push(thisImage.url);
						}
					});
					if (images.length > 1 && images.length % 2 !== 0) {
						oddImages = true;
					}
					thisTiktok = {
						...thisBrand.tiktok,
						images,
					};
				}
				// if (thisBrand.twitter.validated) {
				//  let images = [];
				//  thisBrand.twitter?.images.forEach((thisImage) => {
				//      if (thisImage?.validated) {
				//          images.push(thisImage.url);
				//      }
				//  });
				//  if (images.length > 1 && images.length % 2 !== 0) {
				//      oddImages = true;
				//  }
				//  thisTwitter = {
				//      ...thisBrand.twitter,
				//      images,
				//  };
				// }
				if (thisBrand.email.validated) {
					let images = [];
					thisBrand.email?.images.forEach((thisImage) => {
						if (thisImage?.validated) {
							images.push(thisImage.url);
						}
					});
					if (images.length > 1 && images.length % 2 !== 0) {
						oddImages = true;
					}
					thisEmail = {
						...thisBrand.email,
						images,
					};
				}
			}
			report_json.push({
				...thisBrand,
				instagram: thisInstagram,
				tiktok: thisTiktok,
				news: thisNews,
				// twitter: thisTwitter,
				email: thisEmail,
			});
		});
		if (oddImages) {
			props.setShowSnackBar({
				message:
					"Please validate 1 or even number of images for a sleek looking report",
				type: "error",
			});
			setTimeout(() => {
				props.setShowSnackBar(false);
			}, 5000);
		} else {
			let payload = {
				strategy_id: strategyId,
				report_json,
			};

			props.exportStrategy(payload).then((response) => {
				const { report_pdf } = response;
				if (report_pdf) {
					setPdfLink(report_pdf);
					setIsPDFViewerOpen(true);
				}
			});
		}
	};

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div
					style={{
						color: "#1D1D1D",
						fontSize: "16px",
						fontWeight: 700,
						marginBottom: "10px",
						fontFamily: "Readex Pro",
					}}
				>
					Competitor Analysis
					{strategyDuration && (
						<span className="strategy-duration">
							{`Last ${strategyDuration} week`}{" "}
						</span>
					)}
				</div>
				<div>
					<Button
						variant="contained"
						id={'export'}
						sx={{
							...ContainedButtonStyles,
							marginRight: "15px",
						}}
						onClick={() => exportHandler()}
					>
						Export
					</Button>
					{/* <Button
                        variant="contained"
                        sx={WhiteButtonStyles}
                        onClick={handleViewMore}
                        disabled
                    >
                        View More
                    </Button> */}
				</div>
			</div>
			{data?.length > 0 && (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "10px",
						minHeight: "38px",
					}}
				>
					<div style={{ display: "flex", gap: 15 }}>
						{data.map((thisButton, idx) => {
							return (
								<StyledButton
									idx={idx}
									active={active}
									config={thisButton}
									onClick={handleClick}
								/>
							);
						})}
					</div>
					{/* {active !== 0 && (
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch />}
                                    label="Validated"
                                    onChange={() => handleValidateBrand()}
                                />
                            </FormGroup>
                        </div>
                    )} */}
				</div>
			)}

			{data?.length > 0 && (
				<Grid
					container
					gap={5}
					sx={{ marginTop: "40px", height: "calc(100vh - 220px)" }}
				>
					<Grid item xs={5.15} md={5.15} lg={5.15}>
						<Grid container>
							<Grid item xs={12} md={12} lg={12}>
								<TextAreaComponent
									key={`active-${active}-instagram`}
									data={data[active].instagram}
									type="Instagram"
									handleValidateMedia={() =>
										handleValidateMedia("instagram")
									}
									handleChangeMediaText={(text) =>
										handleChangeMediaText("instagram", text)
									}
									handleValidateImage={(images) => {
										handleValidateImage(
											"instagram",
											images
										);
									}}
								/>
							</Grid>

							<Divider
								orientation="horizontal"
								variant="middle"
								sx={{
									width: "100%",
									margin: "2vh 0px",
									background: "rgba(207, 207, 208, 0.50)",
								}}
							/>

							<Grid item xs={12} md={12} lg={12}>
								<TextAreaComponent
									key={`active-${active}-news`}
									data={data[active].news}
									type="News"
									handleValidateMedia={() =>
										handleValidateMedia("news")
									}
									handleChangeMediaText={(text) =>
										handleChangeMediaText("news", text)
									}
									handleValidateImage={(images) => {
										handleValidateImage("news", images);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Divider
						orientation="vertical"
						variant="middle"
						sx={{ height: "auto", margin: "0 2vh" }}
					/>

					<Grid item xs={5.15} md={5.15} lg={5.15}>
						<Grid container>
							<Grid item xs={12} md={12} lg={12}>
								<TextAreaComponent
									key={`active-${active}-tiktok`}
									data={data[active].tiktok}
									type="Tiktok"
									handleValidateMedia={() =>
										handleValidateMedia("tiktok")
									}
									handleChangeMediaText={(text) =>
										handleChangeMediaText("tiktok", text)
									}
									handleValidateImage={(images) => {
										handleValidateImage("tiktok", images);
									}}
								/>
							</Grid>
							<Divider
								orientation="horizontal"
								variant="middle"
								sx={{
									width: "100%",
									margin: "2vh 0px",
									background: "rgba(207, 207, 208, 0.50)",
								}}
							/>

							{/* <Grid item xs={12} md={12} lg={12}>
                                <TextAreaComponent
                                    key={`active-${active}-twitter`}
                                    data={data[active].twitter}
                                    type="Twitter"
                                    handleValidateMedia={() =>
                                        handleValidateMedia("twitter")
                                    }
                                    handleChangeMediaText={(text) =>
                                        handleChangeMediaText("twitter", text)
                                    }
                                    handleValidateImage={(images) => {
                                        handleValidateImage("twitter", images);
                                    }}
                                />
                            </Grid> */}
							<Grid item xs={12} md={12} lg={12}>
								<TextAreaComponent
									key={`active-${active}-email`}
									data={data[active].email}
									type="Email"
									handleValidateMedia={() =>
										handleValidateMedia("email")
									}
									handleChangeMediaText={(text) =>
										handleChangeMediaText("email", text)
									}
									handleValidateImage={(images) => {
										handleValidateImage("email", images);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			<PDFViwer
				isPDFViewerOpen={isPDFViewerOpen}
				setIsPDFViewerOpen={setIsPDFViewerOpen}
				data={pdfLink}
			/>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getStrategyDetails: (strategy_id) =>
			dispatch(getStrategyDetails(strategy_id)),
		exportStrategy: (payload) => dispatch(exportStrategy(payload)),
		setShowSnackBar: (payload) => dispatch(setShowSnackBar(payload)),
	};
};

export default connect(null, mapDispatchToProps)(Analysis);
