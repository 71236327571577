
import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "./apiReducer";

export const graphSlice = createSlice({
	name: "existingStrategies",
	initialState: {
		existingStrategies: [],
	},
	reducers: {},
});

export const { setExistingStrategies} = graphSlice.actions;

// An syncronous action creator that returns a async function isstead of object as in case of synchronous action creators.
export const getStatstics = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "get", "get_statistics", filters);
    return response || {};
};

export default graphSlice.reducer;
