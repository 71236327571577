import React, { useState } from "react";
import {
	Grid,
	IconButton,
	Paper,
	Modal,
	
	LinearProgress,
	Skeleton,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { cloneDeep } from "lodash";

const ImageLoader = ({
	src,
	alt,
	imageStyle,
	sectionValidated,
	handleImageClick,
	index,
}) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const handleImageLoaded = () => {
		setLoading(false);
		setError(false);
	};

	const handleImageError = () => {
		setError(true);
		setLoading(false);
	};

	console.log({ loading, error, sectionValidated });
	const isDisabled = !sectionValidated || loading || error;
	return (
		<div
			style={{
				pointerEvents: isDisabled ? "none" : "all",
				cursor: isDisabled ? "" : "pointer",
			}}
			onClick={() => handleImageClick(index)}
		>
			{(loading || error) && (
				<Skeleton
					variant="rect"
					width={"7vh"}
					height={"10vh"}
					sx={{ pointerEvents: "none" }}
				/>
			)}
			<img
				id={src}
				src={src}
				alt={alt}
				onLoad={handleImageLoaded}
				onError={() => {
					handleImageError();
				}}
				style={{
					...imageStyle,
					display: loading || error ? "none" : "block",
				}}
			/>
			{(loading || error) && (
				<LinearProgress
					sx={{
						height: 3,
					}}
				/>
			)}
		</div>
	);
};

const ImageList = ({ initialImages, onRemove, validated }) => {
	const [images, setImages] = useState(initialImages);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal open/close

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
		setIsModalOpen(true); // Open the modal when an image is clicked
	};

	const handleCloseModal = () => {
		setIsModalOpen(false); // Close the modal
	};

	const handleRemove = (index) => {
		console.log({ images, index });
		let updatedImages = cloneDeep(images);
		updatedImages[index].validated = !updatedImages[index].validated;
		setImages(updatedImages);
		onRemove(updatedImages);
		if (selectedImageIndex === index) {
			setSelectedImageIndex(null);
		}
	};

	const imageContainerStyle = (index) => ({
		position: "relative",
		// display: "inline-block",
		margin: "8px",
		// cursor: "pointer",
		border: selectedImageIndex === index ? "1px solid #3485FE" : "none", // Highlight selected image
	});

	const imageStyle = {
		minWidth: "7vh",
		width: "7vh",
		height: "10vh",
		minHeight: "10vh",
		objectFit: "cover",
	};

	const closeButtonStyle = {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: "white",
	};

	const modalStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		outline: 0,
	};

	const containerStyle = {
		border: "1px solid #e0e0e0", // Light gray border
		padding: "8px",
		borderRadius: "4px",
		overflow: "hidden", // Ensure border is visible even if children overflow
		minHeight: "12vh",
		height: "12vh",
	};

	return (
		<div>
			{images.length > 0 ? (
				<div>
					<div style={containerStyle}>
						<div style={{ flexGrow: 1, overflowX: "auto" }}>
							<Grid
								container
								spacing={1}
								style={{ flexWrap: "nowrap", paddingBottom: 2 }}
								gap={3}
							>
								{images.map((image, index) => (
									<Grid
										item
										key={index}
										sx={{ margin: "0px" }}
									>
										<Paper
											style={{
												...imageContainerStyle(index),
												margin: 0,
											}} // Pass index to imageContainerStyle
										>
											{validated && image.validated ? (
												<ImageLoader
													key={image.url}
													src={image.url}
													alt={`img ${index}`}
													imageStyle={imageStyle}
													sectionValidated={validated}
													handleImageClick={
														handleImageClick
													}
													index={index}
												/>
											) : (
												<div
													style={{
														minWidth: "7vh",
														width: "7vh",
														height: "10vh",
														minHeight: "10vh",
														background: "#D9D9D9",
													}}
												/>
											)}
											<IconButton
												aria-label="remove"
												style={closeButtonStyle}
												onClick={(e) => {
													e.stopPropagation();
													handleRemove(index);
												}}
												sx={{
													width: "12px",
													height: "12px",
												}}
											>
												{!validated && (
													<RemoveCircleIcon
														sx={{
															color: "lightgray",
															fontSize: "16px",
														}}
													/>
												)}
												{validated &&
													image.validated && (
														<RemoveCircleIcon
															sx={{
																color: "#3B82F6",
																fontSize:
																	"16px",
															}}
														/>
													)}
												{validated &&
													!image.validated && (
														<AddCircleIcon
															sx={{
																color: "#3B82F6",
																fontSize:
																	"16px",
															}}
														/>
													)}
											</IconButton>
										</Paper>
									</Grid>
								))}
							</Grid>
						</div>
						<Modal
							open={isModalOpen}
							onClose={handleCloseModal}
							style={modalStyle}
						>
							<img
								src={images[selectedImageIndex]?.url}
								alt="Selected Image"
								style={{
									maxHeight: "80vh",
									maxWidth: "80vw",
								}}
							/>
						</Modal>
					</div>
				</div>
			) : (
				<div style={containerStyle}></div>
			)}
		</div>
	);
};

export default ImageList;
