import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Paper,
	Button,
	Grid,
	Divider,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@mui/material";
import Tommy from "../../Assets/Tommy.png";
import Lacoste from "../../Assets/Lacoste.png";
import ClearIcon from "@mui/icons-material/Clear";
import {
	TextFieldStyles,
	WhiteButtonStyles,
	ContainedButtonStyles,
	SelectInputStyles,
	DropdownOptionsStyles,
} from "../../CommonStyles/";
import LinkComponent from "../../CommonComponents/LinkComponent";
import SecComponent from "../../CommonComponents/SecComponent";
import {
	getClientsList,
	setOnboardedClients,
	onboardRetailer,
} from "../../Reducers/onBoardingReducer";
import TagIcon from "@mui/icons-material/Tag";
import CancelIcon from "@mui/icons-material/Cancel";
import { productLinesList, regionsList } from "../../Constants";
import Burberry from "../../Assets/Burberry.png";
import Gucci from "../../Assets/Gucci.png";
import CK from "../../Assets/CK.png";

const HeaderComponent = () => {
	const textStyles = {
		color: "#A8A8A8",
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "normal",
		fontFamily: "Readex Pro",
	};
	const cardStyles = {
		// borderRight: "1px solid #D4D4D4",
		display: "flex",
		height: "50px",
		padding: "0px 20px",
		alignItems: "center",
		gap: "10px",
		flexShrink: 0,
		marginBottom: "-10px",
	};
	return (
		<div style={cardStyles}>
			<div style={{ ...textStyles, flexBasis: "17%" }}>Brand</div>
			<div style={{ ...textStyles, flexBasis: "20%" }}>Instagram URL</div>
			<div style={{ ...textStyles, flexBasis: "20%" }}>Twitter URL</div>
			<div style={{ ...textStyles, flexBasis: "20%" }}>Tiktok URL</div>
			<div style={{ ...textStyles, flexBasis: "19%" }}>SEC No</div>
			<div style={{ ...textStyles, flexBasis: "4%" }}>{""}</div>
		</div>
	);
};
const Onboarding = (props) => {
	const clientList = useSelector(
		(state) => state.onBoardingState.onboardedClients
	);
	const [retailerName, setRetailerName] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [selectedProductLine, setSelectedProductLine] = useState(null);

	const [isntaUrl, setInstaUrl] = useState(null);
	const [twitterUrl, settwitterUrl] = useState(null);
	const [tiktokUrl, setTiktokUrl] = useState(null);
	const [sse, setSse] = useState(null);
	const [isUrlGenerated, setIsUrlGenerated] = useState(false);

	useEffect(() => {
		if (clientList?.length === 0) {
			props.getClientsList();
		}
	}, [props, clientList]);

	const handleDelete = (idx) => {
		let newData = [];
		clientList.forEach((thisData, index) => {
			if (index !== idx) {
				newData.push(thisData);
			}
		});
		props.setOnboardedClients(newData);
	};

	const BrandNameComponent = ({ icon, brandName }) => {
		const brandNameStyle = {
			color: "#000",
			fontFamily: "Readex Pro",
			fontSize: "14px",
			fontWeight: 500,
			flexBasis: "70%",
		};
		return (
			<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
				<div style={{ margin: "auto" }}>
					<img
						src={icon}
						alt=""
						style={{
							display: "block",
							height: "25px",
						}}
					/>
				</div>
				<div style={brandNameStyle}>{brandName}</div>
			</div>
		);
	};

	const CardComponent = ({ data, idx, handleDelete }) => {
		const cardStyles = {
			borderRight: "1px solid #D4D4D4",
			borderLeft: "1px solid #D4D4D4",
			background: idx % 2 === 0 ? "#F1F2F8" : "#fff",
			boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
			display: "flex",
			height: "50px",
			padding: "5px 20px",
			alignItems: "center",
			flexShrink: 0,
			marginBottom: "20px",
			justifyContent: "space-between",
		};
		return (
			<div style={cardStyles}>
				<div style={{ width: "17%" }}>
					<BrandNameComponent
						icon={data.logo_url}
						brandName={data.name}
					/>
				</div>
				<div style={{ width: "20%" }}>
					<LinkComponent
						type={"Insta"}
						url={data.insta_url}
						text={data.name}
					/>
				</div>
				<div style={{ width: "20%" }}>
					<LinkComponent
						type={"Twitter"}
						url={data.twitter_url}
						text={data.name}
					/>
				</div>
				<div style={{ width: "20%" }}>
					<LinkComponent
						type={"Tiktok"}
						url={data.tiktok_url}
						text={data.name}
					/>
				</div>
				<div style={{ width: "19%" }}>
					<SecComponent sec_no={data.sec_no} />
				</div>
				<div
					style={{ flexBasis: "4%" }}
					onClick={() => handleDelete(idx)}
				>
					<ClearIcon fontSize="small" sx={{ color: "#ACACAC" }} />
				</div>
			</div>
		);
	};

	const handleGenerateUrl = () => {
		if (retailerName) {
			setInstaUrl("Test@instagram.com");
			settwitterUrl("Test@twitter.com");
			setTiktokUrl("Test@tiktok.com");
			setSse("#12345");
			setIsUrlGenerated(true);
		}
	};

	const isDisabled = () => {
		return !isUrlGenerated;
	};

	const handleOnboard = () => {
		const payload = {
			retailer_details: {
				name: retailerName,
				email: "coach@b.coachoutlet.com",
				insta_url: isntaUrl,
				tiktok_url: tiktokUrl,
				twitter_url: twitterUrl,
			},
		};
		props.onboardRetailer(payload);
	};
	const headerStyles = {
		color: "#263238",
		fontFamily: "Readex Pro",
		fontSize: "16px",
		fontWeight: 500,
		marginBottom: "20px",
	};

	return (
		<div>
			<div
				style={{
					color: "#1D1D1D",
					fontSize: "16px",
					fontWeight: 700,
					marginBottom: "10px",
					fontFamily: "Readex Pro",
				}}
			>
				Onboard a new retailer
			</div>
			<Paper
				elevation={2}
				sx={{
					padding: "20px",
					borderRadius: "4px",
					background: "#FFF",
					boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
					paddingTop: "25px",
					display: "flex",
					flexDirection: "column",
					maxHeight: "calc(100vh - 130px)",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<span style={headerStyles}>Enter Retailer Details</span>
					<Button
						variant="contained"
						sx={WhiteButtonStyles}
						onClick={handleGenerateUrl}
					>
						Generate Social Media Urls
					</Button>
				</div>
				<Grid container>
					<Grid container gap={3} sx={{ alignItems: "center" }}>
						<Grid item xs={2} md={2} lg={2}>
							<TextField
								size="small"
								fullWidth
								id="retailer_name"
								label="Retailer Name"
								name="retailer_name"
								value={retailerName}
								onChange={(e) =>
									setRetailerName(e.target.value)
								}
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								sx={TextFieldStyles}
							/>
						</Grid>
						<Grid item xs={2} md={2} lg={2}>
							<FormControl sx={{ width: "100%" }}>
								<InputLabel
									id="region-label"
									htmlFor="region"
									shrink
									sx={{
										color: "#2563EB",
										fontFamily: "Readex Pro",
									}}
								>
									Region
								</InputLabel>
								<Select
									size="small"
									fullWidth
									variant="outlined"
									sx={SelectInputStyles}
									displayEmpty
									value={selectedRegion}
									onChange={(e) =>
										setSelectedRegion(e.target.value)
									}
									id="region"
									label="Region"
									InputLabelProps={{
										shrink: true,
									}}
								>
									{regionsList.map((option) => {
										return (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={DropdownOptionsStyles}
											>
												{option.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2} md={2} lg={2}>
							<FormControl sx={{ width: "100%" }}>
								<InputLabel
									id="productline-label"
									htmlFor="productline"
									shrink
									sx={{
										color: "#2563EB",
										fontFamily: "Readex Pro",
									}}
								>
									Product Line
								</InputLabel>
								<Select
									size="small"
									fullWidth
									variant="outlined"
									sx={SelectInputStyles}
									displayEmpty
									value={selectedProductLine}
									onChange={(e) =>
										setSelectedProductLine(e.target.value)
									}
									id="productline"
									label="Product Line"
									InputLabelProps={{
										shrink: true,
									}}
								>
									{productLinesList.map((option) => {
										return (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={DropdownOptionsStyles}
											>
												{option.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				{isUrlGenerated && (
					<Divider sx={{ margin: "22px 0px 20px 0px" }} />
				)}
				{isUrlGenerated && (
					<div style={{ ...headerStyles, marginBottom: "0px" }}>
						Social Media Urls
					</div>
				)}
				{isUrlGenerated && (
					<Grid container gap={3}>
						<Grid item xs={2} md={2} lg={2}>
							<TextField
								margin="normal"
								size="medium"
								fullWidth
								id="Instagram"
								label="Instagram"
								name="Instagram"
								value={isntaUrl}
								onChange={(e) => setInstaUrl(e.target.value)}
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								sx={TextFieldStyles}
							/>
						</Grid>
						<Grid item xs={2} md={2} lg={2}>
							<TextField
								margin="normal"
								size="medium"
								fullWidth
								id="Twitter"
								label="Twitter"
								name="Twitter"
								value={twitterUrl}
								onChange={(e) => settwitterUrl(e.target.value)}
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								sx={TextFieldStyles}
							/>
						</Grid>
						<Grid item xs={2} md={2} lg={2}>
							<TextField
								margin="normal"
								size="medium"
								fullWidth
								id="Tiktok"
								label="Tiktok"
								name="Tiktok"
								value={tiktokUrl}
								onChange={(e) => setTiktokUrl(e.target.value)}
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								sx={TextFieldStyles}
							/>
						</Grid>
						<Grid item xs={2} md={2} lg={2}>
							<TextField
								margin="normal"
								size="medium"
								fullWidth
								id="sse"
								label="SEC No."
								name="sec"
								value={sse}
								onChange={(e) => setSse(e.target.value)}
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								sx={TextFieldStyles}
							/>
						</Grid>
					</Grid>
				)}

				<Divider
					sx={{
						margin: isUrlGenerated
							? "0px 0px 20px 0px"
							: "22px 0px 20px 0px",
					}}
				/>
				<span style={headerStyles}>Onboarded Retailers</span>
				<HeaderComponent />
				<div
					style={{
						overflow: "scroll",
						marginTop: "10px",
						minHeight: isUrlGenerated
							? "calc(100vh - 570px)"
							: "calc(100vh - 440px)",
						zIndex: 12,
					}}
				>
					{clientList?.map((thisBrand, idx) => {
						return (
							<CardComponent
								data={thisBrand}
								idx={idx}
								handleDelete={handleDelete}
							/>
						);
					})}
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "16px",
					}}
				>
					<Button
						variant="contained"
						sx={ContainedButtonStyles}
						disabled={isDisabled()}
						onClick={handleOnboard}
					>
						Onboard
					</Button>
				</div>
			</Paper>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getClientsList: () => dispatch(getClientsList()),
		onboardRetailer: (data) => dispatch(onboardRetailer(data)),
		setOnboardedClients: (data) => dispatch(setOnboardedClients(data)),
	};
};

export default connect(null, mapDispatchToProps)(Onboarding);
