import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "./apiReducer";

export const createStrategiesSlice = createSlice({
	name: "createStrategy",
	initialState: {
		existingCompetitors: [],
	},
	reducers: {
		setExistingCompetitors: (state, action) => {
			state.existingCompetitors = action.payload;
		},
	},
});

export const { setExistingCompetitors} = createStrategiesSlice.actions;

// An syncronous action creator that returns a async function isstead of object as in case of synchronous action creators.
export const getExisitingCompetitors = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "get", "list_all_clients", filters); 
	let existingCompetitors = []
	let newCompetitors = []
	response.forEach((thisRetailer)=>{
		if(thisRetailer.is_existing_competitor){
			existingCompetitors.push({...thisRetailer,isChecked:false})
		}
		else{
			newCompetitors.push({...thisRetailer,isChecked:false})
		}
	})
	return {existingCompetitors, newCompetitors} || []
};

export const createNewStrategy = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "post", "create_strategy", filters);
	console.log("createNewStrategy",response)
	return response
};

export const getClientDetails = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "get", "get_client_details", filters);
	console.log("getClientDetails",response)
	return response || []
};

export const addNewCompetitor = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "post", "add_competitors", filters);
	console.log("add_competitors",response)
	let existingCompetitors = []
	let newCompetitors = []
	response.forEach((thisRetailer)=>{
		if(thisRetailer.is_existing_competitor){
			existingCompetitors.push({...thisRetailer,isChecked:false})
		}
		else{
			newCompetitors.push({...thisRetailer,isChecked:false})
		}
	})
	return {existingCompetitors, newCompetitors} || []
};

export const removeCompetitor = (filters) => async (dispatch) => {
	const response = await callApi(dispatch, "delete", "remove_competitors", filters);
	console.log("remove_competitors",response)
	let existingCompetitors = []
	let newCompetitors = []
	response.forEach((thisRetailer)=>{
		if(thisRetailer.is_existing_competitor){
			existingCompetitors.push({...thisRetailer,isChecked:false})
		}
		else{
			newCompetitors.push({...thisRetailer,isChecked:false})
		}
	})
	return {existingCompetitors, newCompetitors} || []
};






export default createStrategiesSlice.reducer;
