import React from "react";
import Avatar from "@mui/material/Avatar";
import IA_LOGO from "../../Assets/IA_LOGO.png";
import NOTIFICATION from "../../Assets/NOTIFICATION.png";
import "./headerbar.css";

const HeaderBar = () => {
	const textStyle = {
		color: "#758490",
		fontSize: "16px",
		fontFamily: "Readex Pro",
		fontWeight: "600",
		marginTop: "20px",
	};

	const nameStyle = {
		color: "#758490",
		fontSize: "16px",
		fontFamily: "Readex Pro",
		fontWeight: "400",
	};
	return (
		<div className="wrapper">
			<div style={{ display: "flex" }}>
				<img alt="ia_logo" src={IA_LOGO} className="logo-ia" />
				<div className="divider"></div>

				<span style={textStyle}>CompeteSmart</span>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
                    marginRight:'20px'
				
				}}
			>
				<img
					alt="mobile_logo"
					src={NOTIFICATION}
					className="notify-ia"
				/>
				<Avatar
					sx={{
						bgcolor: "#7EAAEF",
						fontSize: "14px",
						margin: "0px 15px",
                        height:'32px',
                        width:'32px'
					}}
				>
					JO
				</Avatar>
				<span style={nameStyle}>John</span>
			</div>
		</div>
	);
};

export default HeaderBar;
