import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import merchandiseImage from "../../Assets/merchandise.png";
import forecastingImage from "../../Assets/forecasting.png";
import integrationImage from "../../Assets/integration.png";

const marketingInfo = [
	{
		title: "One Merchandising Platform",
		description:
			"One single end to end platform for all merchandising processes",
		img: merchandiseImage,
	},
	{
		title: "One AI powered accurate forecasting",
		description:
			"A single forecasting powering all the modules creating a single source of truth",
		img: forecastingImage,
	},
	{
		title: "Seamless Integration between processes",
		description:
			"Seamless flow of information making the process efficient and effective",
		img: integrationImage,
	},
];
const MarketingBanner = () => {
	const [intervalId, setIntervalId] = useState(0);
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const slideLength = marketingInfo.length;

	useEffect(() => {
		if (slideLength) {
			setCurrentSlideIndex(0);
		}
	}, []);

	useEffect(() => {
		clearInterval(intervalId);
		const id = setInterval(setNextSlide, 3000);
		setIntervalId(id);
	}, [currentSlideIndex]);

	const setNextSlide = () => {
		let nextSlideIndex = currentSlideIndex + 1;
		if (slideLength - 1 >= nextSlideIndex) {
			setCurrentSlideIndex(nextSlideIndex);
		} else {
			setCurrentSlideIndex(0);
		}
	};

	return (
		<div>
			<Typography
				sx={{
					fontSize: "2.125rem",
					marginBottom: "10px",
					fontFamily: "Readex Pro",
					lineHeight: 1.235,
					letterSpacing: "0.00735em",

				}}
			>
				Powering the AI in Retail
			</Typography>
			<div>
				{currentSlideIndex != null ? (
					<img
						width={"70%"}
						src={marketingInfo[currentSlideIndex].img}
						alt="decorative-image"
					/>
				) : null}
			</div>
			<div>
				{currentSlideIndex != null ? (
					<>
						<Typography
							sx={{
								color: "#1B2E42",
								fontSize: "16px",
								fontFamily: "Readex Pro",
							}}
						>
							{marketingInfo[currentSlideIndex].title}
						</Typography>
						<Typography
							sx={{
								color: "#1B2E42",
								fontSize: "14px",
								opacity: "0.8",
								fontFamily: "Readex Pro",
							}}
						>
							{marketingInfo[currentSlideIndex].description}
						</Typography>
					</>
				) : null}
			</div>
			<div>
				{marketingInfo.map((_item, index) => (
					<CircleIcon
						onClick={() => setCurrentSlideIndex(index)}
						sx={{
							fontSize: "20px",
							color:
								currentSlideIndex === index
									? "#3B82F6"
									: "lightgray",
							marginTop: "10px",
						}}
					/>
				))}
			</div>
		</div>
	);
};

export default MarketingBanner;
