// apiService.js
import axios from "axios";
import AuthService from "../AuthService";

const API_URL =
	"https://competesmart.iaproducts.ai/api/competitor-intelligence";
// process.env.REACT_APP_API_BASE_URL || "http://localhost:3000"; // Default API URL

const axiosInstance = axios.create({
	baseURL: API_URL,
	timeout: 9999999,
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const callApiService = async (method, endpoint, payload) => {
	const config = {
        method,
        url: `${API_URL}/${endpoint}`,
        [method.toLowerCase() === "get" ? "params" : "data"]: payload,
        headers: {
            "Content-Type": "application/json",
            email: AuthService.getAccessToken(),
        },
    };
	try {
		const response = await axiosInstance(config);
		const { data } = response;
		if (response.status === 200 && data.status_code === 200) {
			return { data: response.data, error: false };
		} else {
			return { data: false, error:data?.data?.error ||  "Error in Api Call" };
		}
	} catch (error) {
		console.error("Error making Api Call:", error);
		return { data: false, error: error?.message };
	}
};

export { callApiService };
