import React from "react";
import { Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TagIcon from "@mui/icons-material/Tag";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

const LinkComponent = ({ type, text, url }) => {
	const linkStyle = {
		color: "#0263C5",
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0.25px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	};
	const mediaIcon = {
		Insta: <InstagramIcon fontSize="small" sx={{ color: "#758490" }} />,
		Twitter: <TwitterIcon fontSize="small" sx={{ color: "#758490" }} />,
		Tiktok: <MusicNoteIcon fontSize="small" sx={{ color: "#758490" }} />,
		Sse: <TagIcon fontSize="small" sx={{ color: "#758490" }} />,
	};

	return (
		<div
			style={{
				display: "flex",
				marginRight: "50px",
				alignItems: "flex-start",
				justifyContent: "flex-start",
				gap: 10,
			}}
		>
			{/* <img
				src={mediaIcon[type]}
				alt=""
				style={{
					display: "block",
					height: "20px",
				}}
			/> */}
			{mediaIcon[type]}
			<div style={linkStyle}>
				{url ? (
					<Link href={url} target="_blank">
						{" "}
						{text || url}
					</Link>
				) : (
					<span style={{ color: "black" }}>No Links</span>
				)}
			</div>

			{url ? (
				<CheckCircleIcon fontSize="small" sx={{ color: "#00afa0" }} />
			) : (
				<CancelIcon fontSize="small" sx={{ color: "#fc99a3" }} />
			)}
		</div>
	);
};

export default LinkComponent;
