import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import BULB from "../../Assets/BULB.png";
import DONE from "../../Assets/Done.png";
import THUMBS from "../../Assets/Thumbs.png";
import LIKE from "../../Assets/LIKE.png";
import EYE from "../../Assets/EYE.png";
import EMOJI from "../../Assets/EMOJI.png";
import CREATE_ICON_1 from "../../Assets/CREATE_ICON_1.png";
import CREATE_ICON_2 from "../../Assets/CREATE_ICON_2.png";
import FREQ_ICON_1 from "../../Assets/FREQ_ICON_1.png";
import FREQ_ICON_2 from "../../Assets/FREQ_ICON_2.png";
import ANALYSIS_ICON_1 from "../../Assets/ANALYSIS_ICON_1.png";
import ANALYSIS_ICON_2 from "../../Assets/ANALYSIS_ICON_2.png";
import FB_ICON from "../../Assets/FB_ICON.png";
import INSTA_ICON from "../../Assets/INSTA_ICON.png";
import TIK_ICON from "../../Assets/TIK_ICON.png";

import "./landing.css";

const LandingScreen = ({ setSelected }) => {
	const navigate = useNavigate();
	const Options = [
		{
			header: "Create a new project",
			label: "Start creating new strategies to gather actionable social media data.",
			Icon1: CREATE_ICON_1,
			Icon2: null,
		},
		{
			header: "Explore Competitor Insights",
			label: "Start creating new strategies to gather actionable social media data.",
			Icon1: BULB,
			Icon2: DONE,
		},
		{
			header: "Set Frequency",
			label: "Start creating new strategies to gather actionable social media data.",
			Icon1: FREQ_ICON_1,
			Icon2: null,
			Icon3: FREQ_ICON_2,
		},
		{
			header: "Competitor Analysis",
			label: "Start creating new strategies to gather actionable social media data.",
			Icon1: ANALYSIS_ICON_1,
			Icon2: THUMBS,
		},
	];
	const midTextStyle = {
		height: "87px",
		color: "#394960",
		fontSize: "21px",
		fontFamily: "Readex Pro",
		fontWeight: 600,
		wordWrap: "break-word",
	};
	const greetStyle = {
		color: "black",
		fontSize: "50px",
		fontFamily: "Readex Pro",
		fontWeight: 700,
		wordWrap: "break-word",
		marginBottom: "20px",
	};
	const Card = ({ header, label, Icon1, Icon2, Icon3, idx }) => {
		const [Icon, setIcon] = React.useState(null);
		const onHover = (idx) => {
			if (idx === 2 && Icon3) {
				setIcon(Icon3);
			}
		};
		const onHoverOut = () => {
			setIcon(null);
		};
		return (
			<div
				className={`rotate-container`}
				id={`hover-${idx}`}
				onMouseMove={() => onHover(idx)}
				onMouseOut={() => onHoverOut()}
				style={{
					height: "11vh",
					background: "white",
					boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)",
					borderRadius: "4px",
					display: "flex",
					alignItems: "center",
					marginBottom: "20px",
					padding: "12px 0px",
					zIndex: "2000",
				}}
			>
				<div style={{ flexBasis: "20%", textAlign: "center" }}>
					<div>
						<img
							alt="mobile_logo"
							src={Icon ? Icon : Icon1}
							className="rotate-image"
						/>
						{Icon2 && (
							<img
								alt="mobile_logo"
								src={Icon2}
								className={`thumbs-up-${idx}`}
							/>
						)}
					</div>
				</div>
				<div style={{ flexBasis: "80%" }}>
					<div
						style={{
							color: "black",
							fontSize: "16px",
							fontFamily: "Readex Pro",
							fontWeight: 700,
							wordWrap: "break-word",
							lineHeight: "normal",
							fontStyle: "normal",
						}}
					>
						{header}
					</div>
					<div
						style={{
							width: "100%",
							color: "#758490",
							fontSize: 12,
							fontFamily: "Readex Pro",
							fontWeight: 400,
							wordWrap: "break-word",
							lineHeight: "normal",
							fontStyle: "normal",
						}}
					>
						{label}
					</div>
				</div>
			</div>
		);
	};

	const handleGetStarted = () => {
		navigate("/graphs");
		setSelected(4);
	};
	return (
		<div style={{ display: "flex" }}>
			<div style={{ flexBasis: "30%" }}>
				<div>
					<img alt="mobile_logo" src={EMOJI} className="img-emoji" />
					<img alt="mobile_logo" src={LIKE} className="img-like" />
					<img alt="" src={FB_ICON} className="img-fb" />
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="160"
						height="180"
						viewBox="0 0 216 220"
						fill="none"
						className="img-lnk"
					>
						<path
							d="M108.246 0H107.754C48.2432 0 0 48.936 0 109.302V109.801C0 170.166 48.2432 219.102 107.754 219.102H108.246C167.757 219.102 216 170.166 216 109.801V109.302C216 48.936 167.757 0 108.246 0Z"
							fill="#7EAAEF"
							fill-opacity="0.36"
						/>
						<path
							d="M51.5124 72.8317C48.667 70.1527 47.252 66.8365 47.252 62.891C47.252 58.9454 48.6746 55.4834 51.5124 52.7967C54.3578 50.1177 58.0205 48.7744 62.508 48.7744C66.9956 48.7744 70.5145 50.1177 73.3523 52.7967C76.1977 55.4757 77.6128 58.8456 77.6128 62.891C77.6128 66.9363 76.1902 70.1527 73.3523 72.8317C70.507 75.5107 66.8972 76.854 62.508 76.854C58.1189 76.854 54.3578 75.5107 51.5124 72.8317ZM75.2215 88.1995V170.335H49.6356V88.1995H75.2215Z"
							fill="#FEFFFC"
							fill-opacity="0.84"
						/>
						<path
							d="M160.394 96.314C165.971 102.455 168.756 110.883 168.756 121.615V168.885H144.457V124.946C144.457 119.535 143.072 115.328 140.31 112.334C137.548 109.341 133.824 107.836 129.163 107.836C124.501 107.836 120.778 109.333 118.016 112.334C115.254 115.328 113.869 119.535 113.869 124.946V168.885H89.4258V87.97H113.869V98.7013C116.343 95.1242 119.681 92.2993 123.873 90.2191C128.066 88.1388 132.78 87.1025 138.024 87.1025C147.363 87.1025 154.824 90.173 160.394 96.314Z"
							fill="#FEFFFC"
							fill-opacity="0.84"
						/>
					</svg>
					<img alt="" src={INSTA_ICON} className="img-insta" />
					<img alt="" src={TIK_ICON} className="img-tik" />
					<img alt="mobile_logo" src={EYE} className="img-eye" />
				</div>
			</div>
			<div
				style={{
					flexBasis: "30%",
					// height: "100vh",
					paddingTop: "17%",
					marginRight: "20px",
				}}
			>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<div style={{ width: "90%" }}>
						<div style={greetStyle}>Hey there!</div>
						<div style={midTextStyle}>
							Welcome to CompeteSmart : Click on Get Started on
							the right to Start creating a Project.
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					flexBasis: "33%",
					// 			height: "100vh",
					paddingTop: "8%",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					{Options.map((thisOption, idx) => {
						const { header, label, Icon1, Icon2, Icon3 } =
							thisOption;
						return (
							<Card
								header={header}
								label={label}
								Icon1={Icon1}
								Icon2={Icon2}
								Icon3={Icon3}
								idx={idx}
							/>
						);
					})}
					<Button
						id={'get-started'}
						variant="contained"
						sx={{
							height: "60px",
							borderRadius: "10px",
							background: "#3B82F6",
							color: "white",
							fontSize: "17px",
							fontWeight: "bold",
							boxShadow: "2px 2px 2px 2px #0055AF",
							textTransform: "none",
							fontFamily: "Readex Pro",

							"&:hover": {
								//you want this to be the same as the backgroundColor above
								height: "60px",
								borderRadius: "10px",
								background: "#3B82F6",
								color: "white",
								fontSize: "17px",
								fontWeight: "bold",
								boxShadow: "2px 2px 2px 2px #0055AF",
								textTransform: "none",
								fontFamily: "Readex Pro",
							},
						}}
						onClick={() => handleGetStarted()}
					>
						Get Started
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LandingScreen;
