import { Backdrop } from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import React from "react";

function PDFViwer({ isPDFViewerOpen, setIsPDFViewerOpen, data }) {
    return (
        <Backdrop
            open={isPDFViewerOpen}
            sx={{
                background: "#fff0",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            onClick={() => setIsPDFViewerOpen(false)}
        >
            <div className="pdf-viewer">
            <div style={{ position:'absolute', right:40, top:120 }} onClick={() => setIsPDFViewerOpen(false)}>
					<CancelOutlinedIcon fontSize="large" sx={{ color: "#3B82F6", cursor:'pointer',fontSize:'45px' }} />
				</div>
                <object
                    aria-label="pdf-viewer"
                    type="application/pdf"
                    data={data}
                ></object>
            </div>
        </Backdrop>
    );
}

export default PDFViwer;
