import React from "react";

const BrandNameComponent = ({ icon, brandName }) => {
	const brandNameStyle = {
		color: "#000",
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontWeight: 500,
		flexBasis: "70%",
	};
	return (
		<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
			<div style={{ margin: "auto" }}>
				<img
					src={icon}
					alt=""
					style={{
						display: "block",
						height: "25px",
					}}
				/>
			</div>

			<div style={brandNameStyle}>{brandName}</div>
		</div>
	);
};

export default BrandNameComponent;
