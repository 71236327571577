import { Divider, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { isEmpty } from "lodash";

const GenAIText = ({ data }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [currentData, setCurrData] = useState({});

	useEffect(() => {
		setSelectedIndex(0);
		setCurrData(data[0]);
	}, [data]);

	const handleMove = (type) => {
		if (type === "inc") {
			let nextIdx = selectedIndex + 1;
			if (nextIdx < data.length) {
				setSelectedIndex(nextIdx);
			}
		}
		if (type === "dec") {
			let prevIdx = selectedIndex - 1;
			if (prevIdx >= 0) {
				setSelectedIndex(prevIdx);
			}
		}
	};

	useEffect(() => {
		if (data.length > 0) {
			setCurrData(data[selectedIndex]);
		}
	}, [selectedIndex]);

	console.log("CurrentDataaa", currentData);

	console.log("Propspsps", data);

	return (
		<div
			style={{
				margin: "5px",
				borderRadius: "4px",
				background: "#FFF",
				boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
				padding: "28px 45px 15px 45px",
				height: "540px",
			}}
		>
			{!isEmpty(currentData) && (
				<div>
					<div
						style={{
							fontFamily: "Readex Pro",
							fontSize: "16px",
							color: "rgb(75, 85, 99)",
							fontWeight: 500,
							marginBottom: "5px",
						}}
					>
						<span
							style={{
								marginRight: "10px",
								color: "rgb(117, 132, 144)",
							}}
						>
							Retailer:
						</span>
						<span style={{ fontSize: "16px", fontWeight: "bold" }}>
							{currentData?.client_name}
						</span>
					</div>
					<Divider style={{ marginBottom: "10px" }}></Divider>
					<div
						style={{
							marignTop: "10px",
							marginBottom: "10px",
							color: "rgb(117, 132, 144)",
							fontSize: "14px",
							fontFamily: "Readex Pro",
						}}
					>
						Summary
					</div>
					<div
						style={{
							height: "380px",
							overflow: "scroll",
							width: "100%",
							color: "#1d1d1d",
							fontFamily: "Readex Pro",
							fontSize: "14px",
							fontStyle: " normal",
							fontWeight: 400,
							lineHeight: "19.6px",
						}}
					>
						{currentData?.text}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							marginRight: "-25px",
						}}
					>
						<IconButton
							aria-label="left"
							onClick={(e) => handleMove("dec")}
							sx={{ marginRight: "-10px" }}
						>
							<ArrowCircleLeftIcon
								sx={{
									color:
										selectedIndex === 0
											? "lightgray"
											: "#3B82F6",
									fontSize: "35px",
									disabled: selectedIndex === 0,
								}}
							/>
						</IconButton>

						<IconButton
							aria-label="right"
							onClick={(e) => handleMove("inc")}
						>
							<ArrowCircleRightIcon
								sx={{
									color:
										selectedIndex === data.length - 1
											? "lightgray"
											: "#3B82F6",
									fontSize: "35px",
									disabled: selectedIndex === data.length - 1,
								}}
							/>
						</IconButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default GenAIText;
