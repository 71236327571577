import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Grid, Typography, Divider } from "@mui/material";
import MarketingBanner from "./marketingBanner";
import { ContainedButtonStyles } from "../../CommonStyles";
import { TextFieldStyles } from "../../CommonStyles";
import IA_LOGO from "../../Assets/IA.png";
import AuthService from "../../AuthService";

const Login = ({ authTokens, setAuthTokens }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (authTokens) {
			navigate("/landing");
		}
	}, [authTokens, navigate]);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [error, setError] = useState(false);

	const handleEmailChange = (event) => {
		setError(false)
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setError(false)
		setPassword(event.target.value);
	};

	const handleLogin = async () => {
		try {
			const response = await AuthService.login({
				email,
				password,
			});
			if (response) {
				const { accessToken, refreshToken } = response;
				setAuthTokens({ accessToken, refreshToken });
				navigate("/landing");
				setError(false);
			} else {
				setError(true);
				setAuthTokens(false);
			}
		} catch (error) {
			console.error("Login failed:", error);
		}
	};

	return (
		<Grid
			container
			sx={{ padding: "100px", paddingTop: "50px", background: "#f0f4f9" }}
		>
			<img
				src={IA_LOGO}
				alt="ia_logo"
				height="50px"
				style={{ marginBottom: "10px", marginLeft: "5px" }}
			/>
			<Grid
				container
				sx={{
					padding: "50px",
					borderRadius: "30px",
					background: "#fff",
				}}
			>
				<Grid
					item
					xs={5.8}
					style={{
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<MarketingBanner />
				</Grid>
				<Divider
					orientation="vertical"
					variant="middle"
					sx={{ height: "auto", margin: "0 0" }}
				/>
				<Grid item xs={5.8} style={{ paddingLeft: "100px" }}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<Typography
								variant="h4"
								align="center"
								sx={{ fontFamily: "Readex Pro" }}
							>
								Compete Smart
							</Typography>
						</Grid>
						<Grid
							container
							spacing={2}
							alignItems="center"
							sx={{
								textAlign: "center",
								border: "2px solid #f0f4f9",
								margin: "20px",
								padding: "20px",
								paddingBottom: "60px",
								borderRadius: "30px",
							}}
						>
							<Grid item xs={12}>
								<Typography
									variant="h6"
									align="center"
									sx={{ fontFamily: "Readex Pro" }}
								>
									Login
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									id="email"
									label="Email"
									variant="outlined"
									value={email}
									onChange={handleEmailChange}
									sx={{
										...TextFieldStyles,
										marginBottom: "12px",
									}}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									type="password"
									id="password"
									label="Password"
									variant="outlined"
									value={password}
									onChange={handlePasswordChange}
									sx={{
										...TextFieldStyles,
										marginBottom: "12px",
									}}
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							{error && (
								<Grid item xs={12} sx={{color:'red', fontSize:'10px',textAlign:'left',marginTop:'-15px',marginLeft:'5px'}}>
									Invalid Username or Password
								</Grid>
							)}
							<Grid item xs={12}>
								<Button
									id = {'sign-in'}
									fullWidth
									onClick={handleLogin}
									sx={{
										...ContainedButtonStyles,
										marginBottom: "16px",
									}}
								>
									Sign In
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Login;
