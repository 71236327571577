import { connect, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, OutlinedInput, TextField, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
	WhiteButtonStyles,
	ButtonGroupStyles,
	ContainedButtonStyles,
} from "../../CommonStyles/";
import {
	getAllStrategies,
	refereshAllStrategies,
} from "../../Reducers/allStrategiesReducer";

const STATUS_COLOR = {
	Inprogress: "#2737C2",
	Completed: "#61B53C",
	"Yet To Start": "#FAAD4F",
};
const BrandNameComponent = ({ icon, brandName }) => {
	const brandNameStyle = {
		color: "#394960",
		fontFamily: "Readex Pro",
		fontSize: "15px",
		fontWeight: 500,
		flexBasis: "70%",
	};
	return (
		<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
			<div style={{ margin: "auto" }}>
				<img
					src={icon}
					alt=""
					style={{
						display: "block",
						height: "25px",
					}}
				/>
			</div>
			<div style={brandNameStyle}>{brandName}</div>
		</div>
	);
};
const CardComponent = ({ data, idx, navigate, onLinkClick }) => {
	const {
		retailer_name,
		strategy_name,
		created_on,
		status,
		logo_url,
		strategy_id,
	} = data;
	const borderLeft = `3px solid ${STATUS_COLOR[status]}`;
	const cardStyles = {
		// borderRight: "2px solid #D4D4D4",
		borderLeft,
		background: idx % 2 === 0 ? "#F1F2F8" : "#fff",
		boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
		display: "flex",
		height: "50px",
		padding: "5px 20px",
		alignItems: "center",
		gap: "10px",
		flexShrink: 0,
		marginBottom: "20px",
	};

	const textStyle = {
		color: "#394960",
		flexBasis: "19%",
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontWeight: 500,
	};

	const linkStyles = {
		color: "#0263C5",
		fontFamily: "Readex Pro",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0.25px",
		textDecoration: "underline",
		cursor: "pointer",
	};

	const handleGetStrategyDetails = (strategy_id, retailer_name) => {
		navigate(`/analysis#${strategy_id}`);
	};

	return (
		<div style={cardStyles}>
			<div style={{ flexBasis: "19%" }}>
				<BrandNameComponent
					icon={logo_url}
					brandName={retailer_name}
					status
				/>
			</div>
			<div style={textStyle}>{strategy_name}</div>
			<div style={textStyle}>{created_on}</div>
			<div style={textStyle}>
				<div style={{ display: "flex" }}>
					<span
						style={{
							borderRadius: "50%",
							width: "11px",
							height: "11px",
							marginRight: "10px",
							background: `${STATUS_COLOR[status]}`,
							marginTop: "4px",
						}}
					></span>
					<span>{status}</span>
				</div>
			</div>

			<div style={linkStyles}>
				{status === "Completed" ? (
					<span
						onClick={() => {
							handleGetStrategyDetails(
								strategy_id,
								retailer_name
							);
						}}
						id={'view-competitor'}
					>
						{"View Competitive Analysis"}
					</span>
				) : (
					""
				)}
			</div>
		</div>
	);
};

const HeaderComponent = ({ refereshAllStrategies, setActive }) => {
	const textStyles = {
		color: "#A8A8A8",
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "normal",
		flexBasis: "19%",
		fontFamily: "Readex Pro",
	};
	const cardStyles = {
		// borderRight: "1px solid #D4D4D4",
		display: "flex",
		height: "50px",
		padding: "0px 20px",
		alignItems: "center",
		gap: "10px",
		flexShrink: 0,
		marginBottom: "-10px",
	};
	return (
		<div style={cardStyles}>
			<div style={textStyles}>Client Name</div>
			<div style={textStyles}>Strategy Name</div>
			<div style={textStyles}>Created Date</div>
			<div style={textStyles}>Status</div>
			<div style={textStyles}>{""}</div>
		</div>
	);
};

const buttonConfig = [
	{
		label: "All",
		activeBackground: "#3B82F6",
		activeText: "#fff",
		activeIndicator: "#fff",
		activeIndText: "#3B82F6",
		inActiveBackground: "#fff",
		inActiveText: "#3B82F6",
		inActiveIndicator: "#3B82F6",
		inActiveIndText: "#fff",
	},
	{
		label: "Inprogress",
		activeBackground: "#3B82F6",
		activeText: "#fff",
		activeIndicator: "#fff",
		activeIndText: "#3B82F6",
		inActiveBackground: "#fff",
		inActiveText: "#2737C2",
		inActiveIndicator: "#2737C2",
		inActiveIndText: "#fff",
	},
	{
		label: "Completed",
		activeBackground: "#3B82F6",
		activeText: "#fff",
		activeIndicator: "#fff",
		activeIndText: "#3B82F6",
		inActiveBackground: "#fff",
		inActiveText: "#61B53C",
		inActiveIndicator: "#61B53C",
		inActiveIndText: "#fff",
	},
	{
		label: "Yet To Start",
		activeBackground: "#3B82F6",
		activeText: "#fff",
		activeIndicator: "#fff",
		activeIndText: "#3B82F6",
		inActiveBackground: "#fff",
		inActiveText: "#FAAD4F",
		inActiveIndicator: "#FAAD4F",
		inActiveIndText: "#fff",
	},
];
const StyledButton = ({ active, count, id, onClick, config }) => {
	const {
		label,
		activeBackground,
		activeText,
		activeIndicator,
		activeIndText,
		inActiveBackground,
		inActiveText,
		inActiveIndicator,
		inActiveIndText,
	} = config;
	const labelStyles = {
		color: active ? activeText : inActiveText,
		fontFamily: "Readex Pro",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: "bold",
		lineHeight: "normal",
		marginRight: "6px",
	};
	const bgColor = active ? activeBackground : inActiveBackground;
	return (
		<Button sx={ButtonGroupStyles(bgColor)} onClick={() => onClick(id)} id={`${label}-${id}`}>
			<span style={labelStyles}>{label}</span>
			<span
				style={{
					background: active ? activeIndicator : inActiveIndicator,
					width: "20px",
					height: "20px",
					borderRadius: "50%",
					color: active ? activeIndText : inActiveIndText,
					fontSize: "12px",
					fontWeight: "bold",
				}}
			>
				{count}
			</span>
		</Button>
	);
};
const AllStrategies = (props) => {
	const allStrategies = useSelector(
		(state) => state.allStrategiesState.allStrategies
	);

	const location = useLocation();
	const navigate = useNavigate();
	const [active, setActive] = useState(0);
	const [searchText, setSearchText] = useState("");

	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		props.getAllStrategies();
	}, []);
	function handleClick(id) {
		setActive(id);
	}

	useEffect(() => {}, [location]);

	useEffect(() => {
		let newData = [];
		switch (active) {
			case 1: {
				newData = allStrategies.filter((data) => {
					return data.status === "Inprogress";
				});
				break;
			}
			case 2: {
				newData = allStrategies.filter((data) => {
					return data.status === "Completed";
				});
				break;
			}
			case 3: {
				newData = allStrategies.filter((data) => {
					return data.status === "Yet To Start";
				});
				break;
			}
			default: {
				newData = [...allStrategies];
				break;
			}
		}

		if (searchText) {
			newData = newData.filter((data) => {
				return [
					"retailer_name",
					"strategy_name",
					"created_on",
					"status",
				].reduce(
					(result, key) =>
						result ||
						data[key]
							.toLowerCase()
							.includes(searchText.toLowerCase().trim()),
					false
				);
			});
		}

		setFilteredData(newData);
	}, [active, allStrategies, searchText]);
	console.log("filteredDta", filteredData);
	return (
		<div>
			<div
				style={{
					color: "#1D1D1D",
					fontSize: "16px",
					fontWeight: 700,
					marginBottom: "10px",
					fontFamily: "Readex Pro",
				}}
			>
				Strategies
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "20px",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: 20,
					}}
				>
					{buttonConfig.map((thisButton, idx) => (
						<StyledButton
							config={thisButton}
							active={idx === active}
							count={
								filteredData.filter(
									(item) =>
										item.status === thisButton.label ||
										idx === 0
								).length
							}
							id={idx}
							onClick={handleClick}
						/>
					))}
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 10,
					}}
				>
					<Button
						id={'refresh'}
						variant="contained"
						sx={{ ...ButtonGroupStyles("white"), color: "#3B82F6" }}
						onClick={(e) => {
							console.log("allStrategies",allStrategies)
							let strategy_ids = allStrategies.map((thisStategy)=>{
								return thisStategy.strategy_id
							})

							props.refereshAllStrategies({strategy_ids});
							setActive(0);
						}}
					>
						<RefreshIcon
							sx={{
								color: "#3B82F6",
								fontSize: "35px",
							}}
						/>
					</Button>
					<OutlinedInput
						value={searchText}
						style={{
							height: "37px",
							width: "348px",
							padding: "8px 16px",
							borderRadius: "4px",
							border: "0.5px solid var(--GrayShades-Silver-Chalice, #ACACAC)",
							background: "var(--Text-White, #FFF)",
							fontFamily: "Readex Pro",
							fontSize: "14px",
							color: "var(--Text-Nobel, #AFAFAF)",
							fontWeight: 400,
							marginTop: "5px",
						}}
						placeholder="Search by Strategy Name"
						type="search"
						variant="outlined"
						onChange={(e) => setSearchText(e.target?.value)}
					/>

					<div
						style={{
							border: "1px solid #3B82F6",
							display: "inline-flex",
							width: "36px",
							height: "37px",
							padding: "8px 8px 9px 8px",
							justifyContent: "center",
							alignItems: "center",
							gap: "4px",
							flexShrink: 0,
							marginTop: "5px",
							borderRadius: "4px",
						}}
						onClick={() => {}}
					>
						<FilterAltIcon
							sx={{ color: "#3B82F6", fontSize: "20px" }}
						/>
					</div>
					<Button
						id={'create-strategy'}
						variant="contained"
						sx={WhiteButtonStyles}
						onClick={() => navigate("/create-strategy")}
					>
						Create New Strategy
					</Button>
				</div>
			</div>
			<HeaderComponent
				refereshAllStrategies={props.refereshAllStrategies}
				setActive={setActive}
			/>

			<div
				style={{
					height: "auto",
					overflow: "scroll",
					marginTop: "10px",
				}}
			>
				{filteredData.map((thisStategy, idx) => {
					return (
						<CardComponent
							key={idx}
							data={thisStategy}
							idx={idx}
							navigate={navigate}
						/>
					);
				})}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllStrategies: (data) => dispatch(getAllStrategies(data)),
		refereshAllStrategies: (data) => dispatch(refereshAllStrategies(data)),
	};
};

export default connect(null, mapDispatchToProps)(AllStrategies);
