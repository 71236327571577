import { callApi } from "./apiReducer";
import { createSlice } from "@reduxjs/toolkit";

export const onBoardingSlice = createSlice({
	name: "onBoarding",
	initialState: {
		onboardedClients: [],
	},
	reducers: {
		setOnboardedClients: (state, action) => {
			state.onboardedClients = action.payload;
		},
	},
});

export const { setOnboardedClients } = onBoardingSlice.actions;
// An syncronous action creator that returns a async function isstead of object as in case of synchronous action creators.
export const getClientsList = (filters) => async (dispatch) => {
	const response = await callApi(
		dispatch,
		"get",
		"list_all_clients",
		filters
	);
	if(response){
		dispatch(setOnboardedClients(response));
	}
};

export const onboardRetailer = (payload) => async (dispatch) => {
	const response = await callApi(
		dispatch,
		"post",
		"onboard_retailer",
		payload
	);
	if (response) {
		dispatch(getClientsList());
	}
};

export default onBoardingSlice.reducer;
