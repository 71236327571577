import React from "react";
import TagIcon from "@mui/icons-material/Tag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const SecComponent = ({sec_no}) => {
    return (
        <div
            style={{
                display: "flex",
                marginRight: "50px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 10,
            }}
        >
            <TagIcon fontSize="small" sx={{ color: "#758490" }} />
            <span
                style={{
                    fontFamily: "Readex Pro",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                }}
            >
                {sec_no || "SEC No"}
            </span>
            {sec_no ? (
                <CheckCircleIcon
                    fontSize="small"
                    sx={{ color: "#00afa0" }}
                />
            ) : (
                <CancelIcon fontSize="small" sx={{ color: "#fc99a3" }} />
            )}
        </div>
    );
};

export default SecComponent