import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import store from "../Store";
import Layout from "./Layout";
import "@fontsource/readex-pro/400.css"; // Specify weight

const detectOS = () => {
    const userAgent = window?.navigator?.userAgent;
    if (userAgent.indexOf('Win') !== -1) {
        return 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
        return 'Mac';
    } else {
        return 'Unknown';
    }
};

function App() {
    useEffect(() => {
        const os = detectOS(); // Call detectOS function to get the OS
        console.log("Detected OS:", os);
        // You can perform actions based on the detected OS here
        if (os !== 'Mac') {
            // Load App.css only for Windows
            import("./App.css").then(() => {
                console.log("App.css loaded for Windows");
            }).catch(error => {
                console.error("Failed to load App.css", error);
            });
        }
    }, []);

    return (
        <Provider store={store}>
            <Layout />
        </Provider>
    );
}

export default App;
