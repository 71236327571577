export const regionsList = [
	"US - United States",
	"UK - United Kingdom",
	"EU - European Union",
	"CA - Canada",
	"AU - Australia",
	"CN - China",
	"JP - Japan",
	"IN - India",
	"BR - Brazil",
	"RU - Russia",
	"MX - Mexico",
	"KR - South Korea",
	"SA - Saudi Arabia",
	"ZA - South Africa",
	"AE - United Arab Emirates",
].map((item) => ({
	label: item,
	value: item,
}));

export const productLinesList = [
	"Apparel",
	"Furniture",
	"Grocery",
	"Beauty",
].map((item) => ({
	label: item,
	value: item,
}));

export const socialMediaList = ["Instagram", "Tiktok"].map((item) => ({
	label: item,
	value: item.toLowerCase(),
}));

export const durationOptions = [
	{
		label: "Last 1 Week",
		value: "1",
	},
	{
		label: "Last 2 Week",
		value: "2",
	},
	{
		label: "Last 3 Week",
		value: "3",
	},
	{
		label: "Monthly",
		value: "4",
	},
];

export const customersNameList = [
	"Macy's",
	"Lacoste",
	"Ralph Lauren",
	"Burberry",
	"Calvin Klein",
].map((item) => ({ label: item, value: item }));

const svgStyle = {
	fill: "var(--white, #FFF)",
	filter: "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.10))",
};
export const svg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="50"
		height="51"
		viewBox="0 0 50 51"
		fill="none"
		style={svgStyle}
	>
		<g filter="url(#filter0_d_33_9336)">
			<path
				d="M19.371 -10.1893C21.4983 -12.9255 25.1455 -13.9806 28.4051 -12.803L54.9078 -3.22766C58.1674 -2.04999 60.2979 1.09265 60.1852 4.55662L59.2683 32.7211C59.1555 36.185 56.8251 39.1825 53.4958 40.1456L26.4265 47.9769C23.0972 48.9401 19.5263 47.65 17.5815 44.7813L1.76862 21.4568C-0.176224 18.5881 -0.0526864 14.7934 2.07462 12.0572L19.371 -10.1893Z"
				fill="white"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_33_9336"
				x="0.390625"
				y="-13.2793"
				width="59.7988"
				height="63.5718"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="2" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_33_9336"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_33_9336"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
