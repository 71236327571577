const ACCESS_MANAGEMENT = {
	"th@tommyhilfiger.com": "thpassword",
	"lacoste@tommyhilfiger.com": "lacostepassword",
	"fossildemo@impactanalytics.co": "impact",
	"macydemo@impactanalytics.co": "impact",
	"so5demo@impactanalytics.co": "impact",
	"janmejaya.nanda@impactanalytics.co": "impact",
	"calvinkleindemo@impactanalytics.co": "impact",
	"impactdemo@impactanalytics.co": "impact",
};
const AuthService = {
	login: async ({ email, password }) => {
		const emails = Object.keys(ACCESS_MANAGEMENT);
		if (emails.includes(email)) {
			if (ACCESS_MANAGEMENT[email] === password) {
				localStorage.setItem("accessToken", email);
				localStorage.setItem("refreshToken", email);
				return {
					accessToken: email,
					refreshToken: email,
				};
			}
		}
		return false;
	},
	logout: () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.clear();
		window.location.reload();
	},
	getAccessToken: () => {
		return localStorage.getItem("accessToken");
	},
};

export default AuthService;
