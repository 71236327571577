export const shortNumberFormat = (num) => {
    let parsedNumber = Number(num);

    if (!isNumber(parsedNumber)) return "";

    if (parsedNumber >= 1e9) {
        return parseFloat((parsedNumber / 1e9).toFixed(1)) + "b";
    } else if (parsedNumber >= 1e6) {
        return parseFloat((parsedNumber / 1e6).toFixed(1)) + "m";
    } else if (parsedNumber >= 1e3) {
        return parseFloat((parsedNumber / 1e3).toFixed(1)) + "k";
    } else {
        return parsedNumber.toFixed(0);
    }
};

export const isNumber = (value) => {
    return typeof value === "number" && isFinite(value);
};
