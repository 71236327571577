import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { cloneDeep } from "lodash";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopImage from "../../Assets/TopImage.png";
import LinkComponent from "../../CommonComponents/LinkComponent";
import SecComponent from "../../CommonComponents/SecComponent";
import BrandNameComponent from "../../CommonComponents/BrandNameComponent";
import HeaderComponent from "../../CommonComponents/HeaderComponent";
import { setShowSnackBar } from "../../Reducers/apiReducer";
import {
  ContainedButtonStyles,
  SelectInputStyles,
  TextFieldStyles,
  WhiteButtonStyles,
  DropdownOptionsStyles,
} from "../../CommonStyles/";
import "./style.css";
import {
  // productLinesList,
  regionsList,
  durationOptions,
  // customersNameList,
  svg,
} from "../../Constants";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import {
  getExisitingCompetitors,
  createNewStrategy,
  getClientDetails,
  addNewCompetitor,
  removeCompetitor,
} from "../../Reducers/createStrategyReducer";

const searchBarStyle = {
  width: "100%",
  background: "#fff",
  marginTop: "10px",
};

const ModalCard = ({ brand, onClick, onDelete }) => (
  <div
    className={`card ${brand.is_existing_competitor ? "slider" : ""}`}
    onClick={(e) => onClick(brand, e)}
    id={"modal-card"}
  >
    {brand.is_existing_competitor && (
      <div className="card-cross-icon" id={"card-delete"}>
        <CancelIcon
          fontSize="small"
          sx={{ color: "#88A9FD", zIndex: "2000" }}
          onClick={(e) => onDelete(brand?.id)}
        />
      </div>
    )}
    {brand.isChecked && (
      <div className="card-check-icon">
        {" "}
        id={"card-checkbox"}
        <CheckBoxIcon
          fontSize="small"
          sx={{ color: "#3c82f6", zIndex: "2000" }}
        />
      </div>
    )}
    <img src={brand.logo_url} alt="logo" />
    {svg}
    <div className="card-title">{brand.name}</div>
  </div>
);

const CreateStrategy = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [strategyName, setStrategyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [region, setRegion] = useState(regionsList[0].value);
  const [productLinesList, setProductLinesList] = useState([
    { label: "Apparel", value: "Apparel" },
    { label: "Furniture", value: "Furniture" },
  ]);
  const [productLine, setProductLine] = useState(productLinesList[0].value);
  const [clientDetails, setClientDetails] = useState({});
  const [duration, setDuration] = useState(null);
  const [existingList, setExisting] = useState([]);
  const [newList, setNew] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [customersList, setCustomerList] = useState([]);

  // Manage customers list state
  const [customersNameList, setCustomersNameList] = useState([
    { label: "Macy's", value: "Macy's" },
    { label: "Lacoste", value: "Lacoste" },
    { label: "Ralph Lauren", value: "Ralph Lauren" },
    { label: "Burberry", value: "Burberry" },
    { label: "Calvin Klein", value: "Calvin Klein" },
  ]);

  useEffect(() => {
    dispatch(getClientDetails()).then((data) => {
      if (!isEmpty(data)) {
        setClientName(data.name);
        setClientDetails(data);
        setProductLine(data.product_lines[0]);
      } else {
        setClientDetails(data);
        setExisting([]);
        setNew([]);
      }

      console.log("ClientDetails", data);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(clientName)) {
      dispatch(getClientDetails()).then((data) => {
        if (!isEmpty(data)) {
          setClientName(data.name);
          setClientDetails(data);
        } else {
          setClientDetails(data);
          setExisting([]);
          setNew([]);
        }

        console.log("ClientDetails", data);
      });
    }
  }, [clientName, dispatch]);

  useEffect(() => {
    if (
      !isEmpty(clientDetails) &&
      !isEmpty(clientName) &&
      !isEmpty(region) &&
      !isEmpty(productLine)
    ) {
      dispatch(
        getExisitingCompetitors({
          client_id: clientDetails.id,
          region: region,
          product_line: productLine,
        })
      ).then((data) => {
        console.log("ExistingClientss", data);
        const { existingCompetitors, newCompetitors } = data;
        if (isEmpty(existingCompetitors)) {
          props.setShowSnackBar({
            message: `No existing competitor found for ${clientName} in ${region} region for ${productLine} product line !`,
            type: "info",
          });
          setTimeout(() => {
            props.setShowSnackBar(false);
          }, 5000);
        }
        setExisting(existingCompetitors);
        setNew(newCompetitors);
      });
    }
  }, [clientDetails, clientName, region, productLine, dispatch]);

  const handleDelete = (id) => {
    dispatch(
      removeCompetitor({
        retailer_id: clientDetails?.id,
        competitor_ids: [id],
        product_line: productLine,
        region: region,
      })
    ).then((data) => {
      console.log("DeletedClient", data);
      const { existingCompetitors, newCompetitors } = data;
      if (isEmpty(existingCompetitors)) {
        props.setShowSnackBar({
          message: `No existing competitor found for ${clientName} in ${region} region for ${productLine} product line !`,
          type: "info",
        });
        setTimeout(() => {
          props.setShowSnackBar(false);
        }, 5000);
      }
      setExisting(existingCompetitors);
      setNew(newCompetitors);
    });
  };

  const CardComponent = ({ data, idx, handleDelete }) => {
    const cardStyles = {
      borderRight: "1px solid #D4D4D4",
      borderLeft: "1px solid #D4D4D4",
      background: idx % 2 === 0 ? "#F1F2F8" : "#fff",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
      display: "flex",
      height: "50px",
      padding: "5px 20px",
      alignItems: "center",
      flexShrink: 0,
      marginBottom: "20px",
    };

    const { name, insta_url, twitter_url, tiktok_url, sec_no, logo_url, id } =
      data;

    return (
      <div style={cardStyles}>
        <div style={{ width: "19%" }}>
          <BrandNameComponent icon={logo_url} brandName={name} />
        </div>
        <div style={{ width: "19%" }}>
          <LinkComponent type={"Insta"} url={insta_url} text={name} />
        </div>
        <div style={{ width: "19%" }}>
          <LinkComponent type={"Twitter"} url={twitter_url} text={name} />
        </div>
        <div style={{ width: "19%" }}>
          <LinkComponent type={"Tiktok"} url={tiktok_url} text={name} />
        </div>
        {/* <div style={{ width: "19%" }}>
					<SecComponent sec_no={sec_no} />
				</div> */}
        <div style={{ width: "5%" }} onClick={() => handleDelete(id)}>
          <ClearIcon fontSize="small" sx={{ color: "#ACACAC" }} />
        </div>
      </div>
    );
  };

  const renderClientDetails = () => {
    if (!isEmpty(clientDetails)) {
      const { insta_url, tiktok_url, twitter_url, sec_no } = clientDetails;
      return (
        <>
          <Grid item xs={2} md={2} lg={2}>
            <LinkComponent type={"Insta"} url={insta_url} text={"Instagram"} />
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <LinkComponent
              type={"Twitter"}
              url={twitter_url}
              text={"Twitter"}
            />
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <LinkComponent type={"Tiktok"} url={tiktok_url} text={"Tiktok"} />
          </Grid>
          {/* <Grid item xs={2} md={2} lg={2}>
            <SecComponent sec_no={sec_no} />
          </Grid> */}
        </>
      );
    }
  };

  const handleNameChange = (e) => {
    const newClientName = e.target.value;
    setClientName(newClientName);

    if (!customersNameList.some((client) => client.value === newClientName)) {
      // Add new client to the list
      setCustomersNameList((prevList) => [
        ...prevList,
        { label: newClientName, value: newClientName },
      ]);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Grid item xs={2} md={2} lg={2}>
          <TextField
            size="small"
            fullWidth
            id="strategy_name"
            label="Strategy Name"
            name="strategy_name"
            value={strategyName}
            onChange={(e) => setStrategyName(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={TextFieldStyles}
          />
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <TextField
            slotProps={{
              input: {
                readOnly: true,
              },
            }}
            size="small"
            fullWidth
            id="retailer_name"
            label="Retailer Name"
            name="strategy_name"
            value={clientName}
            onChange={(e) => handleNameChange(e)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={TextFieldStyles}
          />
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="region-label"
              htmlFor="region"
              shrink
              sx={{
                color: "#2563EB",
                fontFamily: "Readex Pro",
              }}
            >
              Region
            </InputLabel>
            <Select
              size="small"
              fullWidth
              variant="outlined"
              sx={SelectInputStyles}
              displayEmpty
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              id="region"
              label="Region"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {regionsList.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={DropdownOptionsStyles}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <TextField
            size="small"
            fullWidth
            id="retailer_name"
            label="Product Line"
            name="strategy_name"
            value={productLine}
            onChange={(e) => handleNameChange(e)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            sx={TextFieldStyles}
          />
          {/* <FormControl sx={{ width: "100%" }}>
            <InputLabel
              id="product-label"
              htmlFor="productline"
              shrink
              sx={{
                color: "#2563EB",
                fontFamily: "Readex Pro",
              }}
            >
              Product Line
            </InputLabel>
            <Select
              size="small"
              fullWidth
              variant="outlined"
              sx={SelectInputStyles}
              displayEmpty
              value={productLine}
              onChange={(e) => setProductLine(e.target.value)}
              id="productline"
              label="Product Line"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {productLinesList.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={DropdownOptionsStyles}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
        </Grid>
      </>
    );
  };

  const handleDuration = (e) => {
    setDuration(e.target.value);
  };

  const headerStyles = {
    color: "#263238",
    fontFamily: "Readex Pro",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "20px",
  };

  const handleCreate = () => {
    if (
      !isEmpty(strategyName) &&
      !isEmpty(clientName) &&
      !isEmpty(region) &&
      !isEmpty(productLine) &&
      !isEmpty(duration)
    ) {
      dispatch(
        createNewStrategy({
          strategy_name: strategyName,
          retailer_id: clientDetails.id,
          region: region,
          product_line: productLine,
          duration,
        })
      ).then((data) => {
        if (data) {
          console.log("StrategyCreatedddd", data);
          navigate(`/all-strategies`);
        }
      });
    }
  };

  const onSelectNew = (brand, e) => {
    let selectedListNew = cloneDeep(newList);
    selectedListNew.forEach((thisBrand) => {
      if (thisBrand.id === brand.id) {
        thisBrand.isChecked = !thisBrand.isChecked;
      }
    });
    setNew(selectedListNew);
  };

  const addNew = () => {
    let added = [];
    newList.forEach((thisItem) => {
      if (thisItem.isChecked) {
        added.push(thisItem.id);
      }
    });

    dispatch(
      addNewCompetitor({
        retailer_id: clientDetails.id,
        region: region,
        product_line: productLine,
        competitor_ids: added,
      })
    ).then((data) => {
      console.log("added", data);
      if (data) {
        console.log("ExistingClientss", data);
        const { existingCompetitors, newCompetitors } = data;
        setExisting(existingCompetitors);
        setNew(newCompetitors);
        setIsModalOpen(false);
      }
    });
  };

  return (
    <div>
      <div
        style={{
          color: "#1D1D1D",
          fontSize: "16px",
          fontWeight: 700,
          marginBottom: "10px",
          fontFamily: "Readex Pro",
        }}
      >
        Create a new project
      </div>
      <Paper
        elevation={2}
        sx={{
          padding: "20px",
          borderRadius: "4px",
          background: "#FFF",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15)",
          paddingTop: "25px",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 130px)",
        }}
      >
        <div style={headerStyles}>Enter Retailer Details</div>
        <Grid
          container
          gap={3}
          sx={{
            marginTop: "15px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          {renderForm()}
        </Grid>
        <Grid container gap={3} sx={{ marginTop: "25px", height: "12px" }}>
          {renderClientDetails(clientDetails)}
        </Grid>

        <Divider sx={{ margin: "15px 0px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={headerStyles}>Existing Competitors</span>
          <Button
            id={"add_new"}
            variant="contained"
            sx={WhiteButtonStyles}
            onClick={() => setIsModalOpen(true)}
          >
            Add New Competitor
          </Button>
        </div>

        {existingList.length > 0 && <HeaderComponent />}

        <div
          style={{
            overflow: "scroll",
            marginTop: "10px",
            minHeight: "calc(100vh - 600px)",
          }}
        >
          {existingList.map((thisBrand, idx) => {
            return (
              <CardComponent
                id={idx}
                data={thisBrand}
                idx={idx}
                handleDelete={handleDelete}
              />
            );
          })}
        </div>
        <Divider sx={{ margin: "20px 0px" }} />
        <div style={headerStyles}>Set Duration for Report Generation</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            sx={{ alignItems: "end" }}
          >
            <Grid item sx={2} md={2} lg={2}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="duration-label"
                  htmlFor="duration"
                  // sx={{ color: "#2563EB", flex: 1 }}
                  sx={{
                    color: "#2563EB",
                    fontFamily: "Readex Pro",
                  }}
                  sixe="medium"
                  shrink
                >
                  Duration
                </InputLabel>
                <Select
                  size="small"
                  fullWidth
                  variant="outlined"
                  sx={SelectInputStyles}
                  displayEmpty
                  value={duration}
                  onChange={handleDuration}
                  id="duration"
                  label="Duration"
                  InputLabelProps={{
                    shrink: true,
                    width: "100px",
                  }}
                >
                  {durationOptions.map((option) => {
                    return (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={DropdownOptionsStyles}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Button
              id={"create_strategy"}
              variant="contained"
              sx={ContainedButtonStyles}
              onClick={(e) => {
                handleCreate(e);
              }}
              disabled={
                isEmpty(strategyName) ||
                isEmpty(clientName) ||
                isEmpty(region) ||
                isEmpty(productLine) ||
                isEmpty(duration)
              }
            >
              Create Strategy
            </Button>
          </Grid>
        </div>
      </Paper>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        // style={modalStyle}
      >
        <div className="modal-container">
          <img className="modal-img" src={TopImage} alt="" />
          <div className="modal-body">
            <div className="cross-bar">
              <ClearIcon
                id={"close-modal-body"}
                fontSize="medium"
                sx={{ color: "#7D7D7D" }}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
            <div className="heading">Select Your Competitor</div>
            <OutlinedInput
              id={"select-brand"}
              style={searchBarStyle}
              value={""}
              placeholder="Type to Select Brand"
              type="search"
              variant="outlined"
              onChange={() => {}}
            />
            <div className="sub-heading">Existing Competitors</div>
            <div className="existing-competitors">
              {existingList.map((brand) => {
                return (
                  <ModalCard
                    id={`existing-brand-${brand.id}`}
                    key={brand.id}
                    brand={brand}
                    onDelete={handleDelete}
                    onClick={() => {}}
                  />
                );
              })}
            </div>
            <div className="sub-heading">Select New Competitors</div>
            <div style={{ minHeight: "265px" }}>
              <div className="new-competitors">
                {newList.map((brand) => (
                  <ModalCard
                    id={`new-brand-${brand.id}`}
                    key={brand.id}
                    brand={brand}
                    onClick={onSelectNew}
                    onDelete={() => {}}
                  />
                ))}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                id={"add"}
                variant="contained"
                sx={{ ...ContainedButtonStyles }}
                onClick={addNew}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowSnackBar: (payload) => dispatch(setShowSnackBar(payload)),
  };
};

export default connect(null, mapDispatchToProps)(CreateStrategy);
