import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CreateStrategy from "../Pages/CreateStrategy";
import AllStrategies from "../Pages/AllStrategies";
import Onboarding from "../Pages/Onboarding";
import SideBar from "../CommonComponents/SideBar/SideBar";
import HeaderBar from "../CommonComponents/HeaderBar";
import LandingScreen from "../Pages/Landing";
import Analysis from "../Pages/Analysis";
import Graphs from "../Pages/Graphs";
import Login from "../Pages/Login";
import { Backdrop, CircularProgress } from "@mui/material";
import AuthService from "../AuthService";

function GradientCircularProgress() {
	return (
		<React.Fragment>
			<svg width={0} height={0}>
				<defs>
					<linearGradient
						id="my_gradient"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop offset="0%" stopColor="rgb(0, 85, 175)" />
						<stop offset="100%" stopColor="#1CB5E0" />
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress
				size={70}
				thickness={2.5}
				sx={{
					animationDuration: "2000ms",
					"svg circle": { stroke: "url(#my_gradient)" },
				}}
			/>
		</React.Fragment>
	);
}
const Layout = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(0);
	const [width, setWidth] = useState("64px");
	const [authTokens, setAuthTokens] = React.useState(
		localStorage.getItem("accessToken")
	);
	useEffect(() => {
		if (isOpen) {
			setWidth("140px");
		}
	}, [isOpen]);
	const protectedRoutes = [
		{
			path: "/landing",
			Component: LandingScreen,
		},

		{
			path: "create-strategy",
			Component: CreateStrategy,
		},
		{
			path: "all-strategies",
			Component: AllStrategies,
		},
		// {
		// 	path: "onboarding",
		// 	Component: Onboarding,
		// },
		{
			path: "analysis",
			Component: Analysis,
		},
		{
			path: "graphs",
			Component: Graphs,
		},
	];

	const showSnackbar = useSelector((state) => {
		return state.apiState.showSnackbar;
	});

	const loading = useSelector((state) => {
		return state.apiState.loading;
	});

	const handleLogout = async () => {
		AuthService.logout();
		setAuthTokens(false);
	};

	return (
		<div>
			{showSnackbar && (
				<Snackbar
					open={showSnackbar}
					severity={showSnackbar.type}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					sx={{ marginTop: "26px" }}
				>
					<Alert severity={showSnackbar.type}>
						{showSnackbar.message}
					</Alert>
				</Snackbar>
			)}
			{loading > 0 && (
				<Backdrop
					open
					sx={{
						color: "#fff",
						zIndex: 5000,
					}}
				>
					<GradientCircularProgress />
				</Backdrop>
			)}
			<BrowserRouter>
				<div
					style={{
						display: "flex",
						height: "100vh",
						overflow: "scroll",
					}}
				>
					{authTokens && (
						<div style={{ width }}>
							<SideBar
								setIsOpen={setIsOpen}
								isOpen={isOpen}
								width={width}
								selected={selected}
								setSelected={setSelected}
								handleLogout={handleLogout}
							/>
						</div>
					)}
					<div style={{ flex: 1 }}>
						<div>
							{" "}
							{authTokens && <HeaderBar />}
							<div
								style={{
									padding: "20px",
									paddingTop: "75px",
									flex: 1,
								}}
							>
								<div>
									<Routes>
										<Route
											path={"/"}
											key={"login"}
											element={
												<Login
													setAuthTokens={
														setAuthTokens
													}
													authTokens={authTokens}
												/>
											}
										/>
										{protectedRoutes.map((thisRoute) => {
											const { Component, path } =
												thisRoute;
											return authTokens ? (
												<Route
													path={path}
													element={
														<Component
															{...props}
															setSelected={
																setSelected
															}
															exact
														/>
													}
												/>
											) : (
												<Route
													path={path}
													element={
														<Navigate to="/" />
													}
												/>
											);
										})}
									</Routes>
								</div>
							</div>
						</div>
					</div>
				</div>
			</BrowserRouter>
		</div>
	);
};

export default Layout;
