import React, { useEffect, useMemo, useState } from "react";
import instagramLogo from "../../Assets/instagram.png";
import tiktokLogo from "../../Assets/TIK_ICON.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import { shortNumberFormat } from "../../util";
import { LinearProgress, Skeleton } from "@mui/material";

const hashtagLink = {
	instagram: "https://www.instagram.com/explore/tags/",
	twitter: "https://twitter.com/hashtag/",
	tiktok: "https://www.tiktok.com/tag/",
};

const handleLinks = {
	instagram: "https://www.instagram.com/",
	twitter: "https://twitter.com/",
	tiktok: "https://www.tiktok.com/",
};

const socialMediaLogoUrls = {
	instagram: instagramLogo,
	twitter:
		"https://cdn.icon-icons.com/icons2/4077/PNG/512/twitter_x_logo_icon_258955.png",
	tiktok: tiktokLogo,
};

const ImageLoader = ({ src, alt, width, height, showLoader }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const handleImageLoaded = () => {
		setLoading(false);
		setError(false);
	};

	const handleImageError = () => {
		setError(true);
		setLoading(false);
	};

	return (
		<div
			style={{
				pointerEvents: "none",
				cursor: "pointer",
				width,
				marginBottom: showLoader ? "25px" : "",
				height,
			}}
		>
			{(loading || error) && (
				<Skeleton
					variant="rect"
					width={width}
					height={height}
					sx={{ pointerEvents: "none" }}
				/>
			)}
			<img
				id={src}
				src={src}
				alt={alt}
				onLoad={handleImageLoaded}
				onError={() => {
					handleImageError();
				}}
				style={{
					display: loading || error ? "none" : "block",
					width,
					height,
					objectFit: "contain",
				}}
			/>
			{(loading || error) && showLoader && (
				<LinearProgress
					sx={{
						height: 3,
					}}
				/>
			)}
		</div>
	);
};

function SocialMediaPost({ socialMedia, data }) {
	const formattedCaption = useMemo(() => {
		let text = data?.caption || "";
		const hashtagRegex = /#(\w+)/g;
		const handleRegex = /@(\w+)/g;

		// Replace hashtags with anchor tags
		return text
			.replace(hashtagRegex, function (match, hashtag) {
				return (
					'<a class="hashtag-text" target="_blank" href="' +
					hashtagLink[socialMedia.value] +
					hashtag +
					'/">#' +
					hashtag +
					"</a>"
				);
			})
			.replace(
				handleRegex,
				`<a class="hashtag-text" target="_blank" href="${
					handleLinks[socialMedia.value]
				}$1">@$1</a>`
			);
	}, [data?.caption, socialMedia.value]);

	return (
		<div className="post-container">
			<div className="header">
				<div>
					<ImageLoader
						key={data.logo_url}
						src={data.logo_url}
						alt="logo"
						width="43.5px"
						height="28.3px"
						showLoader={false}
					/>
				</div>
				<div className="post-title">
					<div className="title">{data.client_name}</div>
					<div className="timedate">
						{/* Sat, Mar 02 2024, 6:55 pm */}
						{moment(new Date(data.timedate)).format("llll")}
					</div>
				</div>
				<div className="social-media-logo">
					<img
						src={socialMediaLogoUrls[socialMedia.value]}
						alt="logo"
					/>
				</div>
			</div>
			<div
				className="post-caption"
				dangerouslySetInnerHTML={{ __html: formattedCaption }}
                
			></div>
			<div>
				<ImageLoader
					key={data?.image}
					src={data?.image}
					alt="post"
					width="100%"
					height="240px"
					showLoader={true}
				/>
			</div>
			<div className="total-engagment">
				<span>Total Engagement</span>
				<span>{shortNumberFormat(data.total_engagement)}</span>
			</div>
			<div className="like-share">
				<div>
					<FavoriteIcon style={{ color: "#e2425c" }} />
					<span>{shortNumberFormat(data.like_share)}</span>
				</div>
				<div>
					<ChatBubbleOutlineIcon
						style={{ color: "#ACACAC", marginTop: "4px" }}
					/>
					<span>{shortNumberFormat(data.comment)}</span>
				</div>
				<div>
					<ShareIcon style={{ color: "#ACACAC" }} />
					<span>NA</span>
				</div>
			</div>
		</div>
	);
}

export default SocialMediaPost;
