export const TextFieldStyles = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#2563EB", // Change the border color here
			borderRadius: 2.5, // Adjust the border radius to make it rounded
			height: "56px",

		},
		"&:hover fieldset": {
			borderColor: "#2563EB", // Change the border color on hover if needed
		},
		"&.Mui-focused fieldset": {
			borderColor: "#2563EB", // Change the border color when focused
		},
	},
	"& .MuiInputLabel-root": {
		color: "#2563EB", // Change the label color here
		/* Typography/label/small */
		fontFamily: "Readex Pro",
		// fontSize: "15px",
	},
	"& input": {
		fontSize: "14px", // Change the font size as desired
		fontFamily: "Readex Pro",
		color: "#475569",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0.25px",
		marginTop: "auto",
		marginBottom: "auto",
	},
	"& .MuiInputBase-input": {
		fontSize: "14px", // Change the font size as desired
		fontFamily: "Readex Pro",
		color: "#475569",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0.25px",
		marginTop: "6px",
		marginBottom: "auto",
	},
};

export const WhiteButtonStyles = {
	height: "37px",
	backgroundColor: "white",
	borderRadius: "4px",
	border: "1px solid #3B82F6",
	borderBottom: "1px solid #3B82F6", // Change border color as needed
	color: "#3B82F6",
	boxShadow: "0px 3px 0px 0px #0956CA",
	fontWeight: 500,
	fontSize: "14px",
	textTransform: "none",
	padding: "8px 24px",
	fontFamily: "Readex Pro",
	"&:hover": {
		height: "37px",
		border: "1px solid #3B82F6",
		backgroundColor: "white",
		borderRadius: "4px",
		borderBottom: "1px solid #3B82F6", // Change border color as needed
		color: "#3B82F6",
		boxShadow: "0px 3px 0px 0px #0956CA",
		fontWeight: 500,
		fontSize: "14px",
		padding: "8px 24px",
		fontFamily: "Readex Pro",
	},
};

export const SelectInputStyles = {

	borderColor: "#2563EB",
	borderRadius: 2.5,

	"& .MuiOutlinedInput-input": {
		// For Selected Value 
		fontSize: "14px", // Change the font size as desired
		fontFamily: "Readex Pro",
		color: "#475569",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "20px",
		letterSpacing: "0.25px",
		marginTop: "6px",
		marginBottom: "auto",
	},

	"& .MuiOutlinedInput-notchedOutline": {
		// This is the actual border
		borderColor: "#2563EB", // Change border color as needed
		height: "56px",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		// On Hover color should remain same
		borderColor: "#2563EB", // Change border color on hover
	},
	'.MuiSvgIcon-root ': {
		fill: "#2563EB !important",
	  }
};

export const ChartSelectStyles = {
	width:'150px', height:'50px',	borderColor: "white",
	"& .MuiOutlinedInput-input": {
		// For Selected Value 
		fontSize: "14px", // Change the font size as desired
		fontFamily: "Readex Pro",
		color: "#475569",
		fontStyle: "normal",
		fontWeight: 400,
		marginTop:0.75,
		letterSpacing: "0.25px",
		marginBottom: "auto",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		// This is the actual border
		borderColor: "white", // Change border color as needed
		height:'50px'
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		// On Hover color should remain same
		height:'50px',
		borderColor: "white", // Change border color on hover
	},
}

export const ButtonGroupStyles = (background) => {
	return {
		backgroundColor: "white",
		padding: "6px 20px",
		borderRadius: "50px",
		boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
		fontFamily: "Readex Pro",
		fontWeight: 500,
		fontSize: "14px",
		background,
		textTransform: "none",
		"&:hover": {
			backgroundColor: "white",
			borderRadius: "50px",
			boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
			fontFamily: "Readex Pro",
			fontWeight: 500,
			fontSize: "14px",
			background,
			textTransform: "none",
		},
	};
};

export const ContainedButtonStyles = {
	padding: "8px 24px",
	height: "37px",
	borderRadius: "10px",
	background: "#3B82F6",
	color: "white",
	fontSize: "12px",
	fontWeight: "bold",
	boxShadow: "2px 2px 2px 2px #0055AF",
	textTransform: "none",
	fontFamily: "Readex Pro",

	"&:hover": {
		padding: "8px 24px",
		height: "37px",
		borderRadius: "10px",
		background: "#3B82F6",
		color: "white",
		fontSize: "12px",
		fontWeight: "bold",
		boxShadow: "2px 2px 2px 2px #0055AF",
		textTransform: "none",
		fontFamily: "Readex Pro",
    }
};

export const DropdownOptionsStyles = 
	{
		background: "white",
		fontSize: "14px",
		fontFamily: "Readex Pro",
		"&:hover": {
			backgroundColor: "#E5EDF7",
		},
	}

