import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import "./Sidebar.css";

const SideBar = ({ isOpen, setIsOpen, width, selected, setSelected, handleLogout }) => {
	const navigate = useNavigate();
	const handleNavigate = (path, idx) => {
		setSelected(idx);
		navigate(path);
	};
	const iconStyles = {
		color: "white",
	};

	const sideBarOptions = [
		{
			icon: <MenuIcon sx={iconStyles} />,
			label: "Get Started",
			navigateTo: "/landing",
		},
		{
			icon: <AddBoxOutlinedIcon sx={iconStyles} />,
			label: "Create Strategy",
			navigateTo: "create-strategy",
		},
		{
			icon: <ArticleOutlinedIcon sx={iconStyles} />,
			label: "All Strategies",
			navigateTo: "all-strategies",
		},
		// {
		// 	icon: <GroupAddOutlinedIcon sx={iconStyles} />,
		// 	label: "Onboarding",
		// 	navigateTo: "onboarding",
		// },
		{
			icon: <AutoGraphIcon sx={iconStyles} />,
			label: "Platformwise Report",
			navigateTo: "graphs",
		},
	];
	const bottomOptions = [
		{
			icon: <SettingsOutlinedIcon sx={{...iconStyles, color: '#4A7BDA'}} />,
			label: "Settings",
			onClick: ()=> {},
		},
		{
			icon: <LogoutIcon sx={{...iconStyles, color: '#4A7BDA'}} />,
			label: "Logout",
			onClick: ()=> handleLogout(),
		},
	]
	const wrapperStyle = {
		position: "fixed",
		top: 0,
		width,
		height: "100vh",
		// background:	"linear-gradient(180deg, #75A6F1 0%, rgba(117, 166, 241, 0) 100%)",
		background:
			"linear-gradient(180deg, #4A7BDA 0%, rgba(197, 230, 252, 0.50) 100%)",
		zIndex: 200,
		display:'flex',
		flexDirection:'column',
		justifyContent:'space-between'
	};

	const itemMenuStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "55px",
		width: "64px",
	};

	const buttontyles = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "48px",
		width: "64px",
	};
	return (
		<div style={wrapperStyle}>
			<div>
				{sideBarOptions.map((thisOption, idx) => {
					const isSelected = idx === selected;
					const styles =
						idx === 0 ? { ...itemMenuStyle } : { ...buttontyles };
					if (isSelected) {
						styles["backgroundColor"] = "#0055AF";
					}
					return (
						<Tooltip
							title={`${thisOption.label}`}
							placement="right"
							arrow
						>
							<div
								id = {thisOption.label}
								style={styles}
								className={"sidebar-items-hover"}
								onClick={() =>
									handleNavigate(thisOption.navigateTo, idx)
								}
							>
								{thisOption.icon}
							</div>
						</Tooltip>
					);
				})}
			</div>
			<div>
			{bottomOptions.map((thisOption,idx)=>{
					return (
						<Tooltip
							title={thisOption.label}
							placement="right"
							arrow
						>
							<div
								style={buttontyles}
								className={"sidebar-items-hover"}
								onClick={() =>
									thisOption.onClick()
								}
							>
								{thisOption.icon}
							</div>
						</Tooltip>
					);
			})}
			</div>
		</div>
	);
};

export default SideBar;
