import { configureStore } from "@reduxjs/toolkit";
import apiReducer from "../Reducers/apiReducer";
import createStrategyReducer from "../Reducers/createStrategyReducer";
import onBoardingReducer  from "../Reducers/onBoardingReducer";
import allStrategiesReducer from "../Reducers/allStrategiesReducer";
import graphReducer from "../Reducers/graphReducer";

// Import all reducers here

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
	// Automatically calls `combineReducers`
	reducer: {
		apiState: apiReducer,
		createStrategyState: createStrategyReducer,
		onBoardingState: onBoardingReducer,
		allStrategiesState: allStrategiesReducer,
		graphState: graphReducer
	},
	devTools: process.env.NODE_ENV !== "production",
});

export default store;
